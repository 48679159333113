import React, {useState} from 'react'
import {Stack} from '@mui/material'

import {RootState} from '../../../store'
import {useSelector} from 'react-redux'
import SimpleLoading from '../../customTable/SimpleLoading'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useTranslation} from 'react-i18next'
import PopUp from '../../customTable/PopUp'
import Invoice from './Invoice'
import NameText from '../../customTable/NameText'
import NewInvoice from './NewInvoice'
import Label from '../../customTable/Label'

export default function Row({
  item,
  i,
}: {
  item: any
  i: number
  setRows: any
  rows: any
  setIsLoading: any
}) {
  const [open, setOpen] = React.useState(false)
  const [editItem, setEditItem] = useState(item)
  const handelOpen = () => {
    setOpen(true)
  }

  const [isDeleted, setDeleted] = useState(true)

  const {lang, defLang} = useSelector((state: RootState) => state.vender)
  const {t} = useTranslation()

  return (
    <>
      {isDeleted && (
        <>
          {' '}
          <tr className='fw-bold text-muted '>
            <td onClick={handelOpen} style={{cursor: 'pointer'}}>
              <Label color='#009EF7' text={i + 1} />
            </td>
            <td style={{textAlign: 'start'}}>
              <span className='text-muted me-2 fs-7 fw-semibold'>{editItem.type}</span>
            </td>
            <td style={{textAlign: 'start'}}>
              <span className='text-muted me-2 fs-7 fw-semibold'>{editItem.price}</span>
            </td>
            <td style={{textAlign: 'start'}}>
              <NameText text={editItem.name && editItem.name[lang || defLang]} />
            </td>
            <td style={{textAlign: 'start'}}>{editItem.start_at}</td>
            <td style={{textAlign: 'start'}}>{editItem.expire_at}</td>
            <td style={{textAlign: 'start'}}>
              <td style={{textAlign: 'end'}}>
                <Stack
                  alignItems={'center'}
                  sx={{background: 'var(--bs-light)'}}
                  borderRadius={'8px'}
                  className='menu-item '
                >
                  <a
                    style={{display: 'flex', gap: '5px', color: '#008dff'}}
                    className='menu-link'
                    onClick={handelOpen}
                  >
                    <img
                      src={toAbsoluteUrl('/media/avatars/eye.png')}
                      alt='SVG Image'
                      style={{width: '15px', marginRight: '5px'}}
                    />

                    {t('view')}
                  </a>
                </Stack>
              </td>
            </td>
          </tr>
          <PopUp open={open} setOpen={setOpen} width='sm'>
            {open && <NewInvoice id={editItem.id} />}
          </PopUp>
        </>
      )}
    </>
  )
}
