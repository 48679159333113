import {Box, Container, Stack, Typography} from '@mui/material'
import React, {useState, useEffect} from 'react'
import AddIcon from '@mui/icons-material/Add'
import VariationsHeadTable from './VariationsHeadTable'
import VariationsRow from './VariationsRow'
import AddVariationsRow from './AddVariationsRow'
import variations from '../../../../../Api/variations'
import {useParams} from 'react-router-dom'
import VariationsRowStatic from './StaticRow'
import {useTranslation} from 'react-i18next'
import MainBtn from '../../../../customTable/MainBtn'

export default function Variations({data, setData}: any) {
  const [open, setOpen] = React.useState(false)
  const {id} = useParams()
  const [image, setImage] = useState('')
  const [isLoadingVariations, setIsLoadingVariations] = useState(false)
  const getVariations = () => {
    variations.getVariations(
      id,
      (res: any) => {
        setData(res.data.product_variations)
        setImage(res.data.product_image)
      },
      setIsLoadingVariations
    )
  }
  const [options, setOptions] = React.useState([])

  const [added, setAdded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const getProductOption = () => {
    variations.getProductOption(
      id,
      (res: any) => {
        setOptions(res.data)
      },
      setIsLoading
    )
  }
  useEffect(() => {
    getVariations()
    getProductOption()
  }, [])
  useEffect(() => {
    if (setAdded) {
      setAdded(false)
    }
  }, [data])
  const {t} = useTranslation()

  return (
    <Stack mt={'40px'}>
      <Box gap={'20px'} display={'flex'}>
        <MainBtn
          backageKey={'PRODUCT_VARIATION_NUM'}
          num={data.length}
          width='auto'
          handelSubmit={() => {
            setOpen(true)
          }}
          text='newVariation'
        />
      </Box>
      <Stack
        mt={'24px'}
        sx={{
          overflowX: 'scroll',
          background: 'var(--bs-body-bg)',
          border: '1px solid var(--bs-gray-300)',
          borderRadius: '8px',
          // margin: '0 20px',
        }}
        width={'100%'}
      >
        <Stack
          // width={'100%'}
          // sx={{background: 'red'}}
          minWidth={'1050px'}
        >
          <VariationsHeadTable />
          {added ? (
            <h1>loading</h1>
          ) : (
            <>
              {' '}
              {data.map((e: any, i: number) => (
                <VariationsRow
                  rows={data}
                  setRows={setData}
                  setAdded={setAdded}
                  image={image}
                  item={e}
                  i={i}
                  key={i}
                />
              ))}
            </>
          )}
          <VariationsRowStatic options={options} image={image} i={data.length} />
          <AddVariationsRow
            setAdded={setAdded}
            setData={setData}
            data={data}
            open={open}
            setOpen={setOpen}
            options={options}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}
