import {Box, Container, Stack, Typography} from '@mui/material'
import React, {useState, useEffect} from 'react'
import UploadImg from '../customTable/UploadImg'
import ErrorMsg from '../customTable/ErrorMsg'
import RedStart from '../customTable/RedStart'
import InputType from '../customTable/InputType'
import MySelect from '../customTable/CustomSelectTest'
import SaveButton from '../customTable/SaveButton'
import Banner from '../../Api/banner'
import IBanner from '../../interface/IBanner'
import * as Yup from 'yup'
import Loading from '../customTable/LoadingForm'
import LabelInput from '../customTable/LabelInput'
import TabsWithOutFlag from '../sittings/general/TabsWithOutFlag'
import Tabs from '../customTable/Tabs'
import Manufacturers from '../../Api/manufacturers'
import {RootState} from '../../store'
import {useSelector} from 'react-redux'
import TabsWithFlag from '../customTable/LangTabsWithFlag'
import {useTranslation} from 'react-i18next'
import {toast} from 'react-toastify'
import OptionCatalog from '../../Api/optionCatalog'
import {OptionCatalogSchema} from '../../yup/OptionCatalog'
import ValidationFun from '../../yup/ValidationFun'
import TabsLangWithFlag from '../customTable/LangTabs'

export default function Form({
  rows,
  setRows,
  setOpen,
  open,
  setAdded,
  setNum,
}: {
  rows: any[] | never[]
  setRows: React.Dispatch<React.SetStateAction<any[] | never[]>>
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  open: boolean
  setAdded: any
  setNum: any
}) {
  const [base64Image, setBase64Image] = React.useState('')
  const [banner, setBanner] = useState({sort: 0})
  const [msgErr, setMsgErr] = React.useState<string>('')
  const [isLoading, setUsLoading] = React.useState(false)
  const {t} = useTranslation()

  const store = useSelector((state: RootState) => state.vender)

  const [defLang, setDefLang] = useState('')
  const [selectedLang, setSelectedLang] = useState<any>([])
  const getLangs = async () => {
    setSelectedLang(store.langs)
    setDefLang(
      store.langs.some((e: any) => e.code === store.defLang) ? store.defLang : store.langs[0].code
    )
  }
  useEffect(() => {
    getLangs()
  }, [store.langs])
  const [languagess, setlanguagess] = useState(['en'])

  useEffect(() => {
    setlanguagess(store.storeLangs)
  }, [store.storeLangs])
  const [errors, setErrors] = useState<any>({})
  const saveChange = () => {
    ValidationFun(banner, OptionCatalogSchema(languagess), setErrors, (value: any) => {
      OptionCatalog.add(
        {
          ...banner,
        },
        (res: any) => {
          setBase64Image('')
          setMsgErr('')
          setUsLoading(false)
          setOpen(false)
          setRows([{...res, status: 1}, ...rows])
          setBanner({sort: 0})
          setAdded(true)
          setNum((prev: any) => prev + 1)
        },
        setUsLoading
      )
    })
  }
  return (
    <Container
      lang={store.lang}
      dir={store.isRtl === 0 ? 'ltr' : 'rtl'}
      sx={{padding: '7px 24px 28px 24px'}}
      maxWidth='md'
    >
      <p style={{width: '500px'}}></p>
      <Typography sx={{fontSize: '20px', fontWeight: '600', color: 'var(--bs-body-color)'}}>
        {t('addOptionCatalog')}
      </Typography>
      <Stack mt='24px' position={'relative'} width={{xs: '100%'}} gap='10px'>
        {selectedLang.map((e: any, i: number) => (
          <>
            {e.code === defLang && (
              <>
                <label style={{display: 'flex', gap: '5px'}} htmlFor={e.code}>
                  <LabelInput text={t('name')} />
                  <RedStart />
                </label>
                <Box position={'relative'} sx={{width: {sm: '100%', xs: '100%'}}}>
                  <InputType
                    name={'name'}
                    change={setBanner}
                    value={banner}
                    type='text'
                    child={e.code}
                    errors={errors}
                  />
                  {/* <ErrorMsg msgErr={msgErr} name='address' /> */}
                </Box>
              </>
            )}
          </>
        ))}
        <Stack
          position={'absolute'}
          right={store.isRtl === 0 ? '0' : 'auto'}
          left={store.isRtl === 1 ? '0' : 'auto'}
          top='24px'
        >
          <TabsLangWithFlag
            activeLang={defLang}
            setActiveLang={setDefLang}
            languages={selectedLang}
            name={'name'}
            msgErr={errors}
          />
        </Stack>
      </Stack>

      <Stack mt='24px' width={'100%'} position={'relative'} gap={'12px'}>
        <Stack direction={'row'} gap='5px'>
          <label style={{display: 'flex', gap: '5px'}}>
            <LabelInput text={t('sort')} />
          </label>
          {/* <RedStart /> */}
        </Stack>
        <InputType errors={errors} name={'sort'} change={setBanner} value={banner} type='number' />
      </Stack>
      <SaveButton text={t('saveChanges')} submit={saveChange} isLoading={false} setOpen={setOpen} />
      <Stack alignItems={'flex-end'}>
        <ErrorMsg msgErr={msgErr} name='' />
      </Stack>

      {isLoading && <Loading />}
    </Container>
  )
}
