import {FormikProps} from 'formik'
import {InitialValues} from '../validationSchema'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../store'
import OldInput from '../../../customTable/OldInput'
import {Stack} from '@mui/material'

type Props = {
  label: string
  formik: FormikProps<InitialValues>
  unit: string
  name: keyof InitialValues
  required?: boolean
  type?: string
  errors?: any
}

export const InputCurtomWithLabel = (props: Props) => {
  const {label, formik, unit, name, required, errors, type} = props
  const store = useSelector((state: RootState) => state.vender)

  return (
    <div className='mb-10'>
      <label className={`form-label ${required ? 'required' : ''}`}>{label}</label>
      <Stack width={'100%'} position={'relative'} className=' fv-row'>
        <OldInput formik={formik} min={0} errors={errors} type='number' name={`${name}`} />
        <Stack
          position={'absolute'}
          sx={{
            minWidth: '50px',
            left: store.isRtl !== 0 ? 0 : 'auto',
            right: store.isRtl === 0 ? 0 : 'auto',
            height: '46.5px',
          }}
          className='col-1  fv-row d-flex align-items-center justify-content-center bg-gray-300  border border-gray-300 border-1-none border rounded '
        >
          <span className='text-center text-bold fs-2 py-2'>{unit}</span>
        </Stack>
      </Stack>
    </div>
  )
}
