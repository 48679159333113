import React, {useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import ErrorMsg from './NewMsgErr'

interface Props {
  type: string
  value: any
  name: string
  change: any
  child?: string
  checked?: boolean
  placeholder?: any
  withoutBorder?: any
  changeName?: any
  isLink?: any
  otherName?: any
  errors?: any
  maxLength?: any
}

const InputType: React.FC<Props> = ({
  type,
  value,
  name,
  change,
  child,
  checked,
  placeholder,
  withoutBorder,
  changeName,
  isLink,
  otherName,
  errors,
  maxLength,
}) => {
  const [inputValue, setInputValue] = useState('')

  const {t} = useTranslation()
  useEffect(() => {
    if (child && (!value || !value[name])) {
      change({...value, [name]: {}})
    }
  }, [child, name, change, value])

  useEffect(() => {
    if (child) {
      setInputValue(value && value[name] && value[name][child] ? value[name][child] : '')
    } else {
      setInputValue(value && value[name] ? value[name] : '')
    }
  }, [child, name, value])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    setInputValue(newValue)
    if (changeName && child) {
      change({
        ...value,
        [name]: {...value[name], [child]: newValue},
        [changeName]: {},
        isLink: isLink,
      })
    } else if (child) {
      if (otherName) {
        change({
          ...value,
          [name]: {...value[name], [child]: newValue},
          [otherName]: {...value[otherName], [child]: newValue.trim().replace(/\s/g, '-')},
        })
      } else {
        change({...value, [name]: {...value[name], [child]: newValue}})
      }
    } else {
      change({...value, [name]: type === 'number' ? +newValue : newValue})
    }
  }
  return (
    <>
      <input
        maxLength={maxLength || 'auto'}
        style={{
          padding: withoutBorder ? '12.0625px 4px 12.5px' : '12.0625px 13px 12.5px',
          background: checked ? '#c4d3e814' : 'var(--bs-body-bg)',
          border: withoutBorder ? 'none' : '1px solid var(--bs-gray-300)',
          borderRadius: '6.18px',
          width: '100%',
          color: 'var(--bs-text-dark)',
        }}
        className='login-input'
        placeholder={`${t(placeholder)}`}
        min={0}
        value={inputValue ? inputValue : !inputValue && type === 'number' ? '' : inputValue}
        onChange={handleChange}
        type={type}
        disabled={checked || false}
      />
      {errors && !child && errors[name] && typeof errors[name] === 'string' ? (
        <ErrorMsg msgErr={errors[name]} />
      ) : errors &&
        child &&
        errors[`${name}.${child}`] &&
        typeof errors[`${name}.${child}`] === 'string' ? (
        <ErrorMsg child={child} msgErr={errors[`${name}.${child}`]} />
      ) : (
        ''
      )}
    </>
  )
}

export default InputType
