import React, {useCallback, useState} from 'react'
import {FormikErrors, FormikTouched, useFormik} from 'formik'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {RootState, useDispatch, withToastr} from '../../../../store'
import * as Yup from 'yup'

import {
  createItemBulkAction,
  getBulkById,
  removeBulkAction,
  updateItemBulkAction,
} from '../../../../store/vender/products/thunk'
import {toast} from 'react-toastify'
import {Bulkschema} from '../../../../yup/Bulk'
import DeletedMsg from '../../../customTable/DeleteMsg'
import ValidationFun from '../../../../yup/ValidationFun'
import ErrorMsg from '../../../customTable/NewMsgErr'
import Product from '../../../../Api/product'
import SimpleLoading from '../../../customTable/SimpleLoading'
export interface BulkDiscount {
  min: number
  max: number
  is_fixed: number
  value: number
  id?: number
}

export default function DisCountItem({
  rowSlectedToEdit,
  index,
  formik,
  id,
  setIsEditing,
  setRowSlectedToEdit,
  // handelSaveAndUpdate,
  setIsCreated,
  setActiveAdd,
}: any) {
  const {t} = useTranslation()
  const {bulkDiscount} = useSelector((state: RootState) => state.product)
  const dispatch = useDispatch()
  const [msgErr, setMsgErr] = useState<string>('')
  const [isEditable, setIsEditable] = useState(false)
  const [errors, setErrors] = useState<any>({})
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)
  const handelSaveAndUpdate = useCallback(
    (index: number) => {
      ValidationFun(
        {
          ...formik.values.optionDetails[index],
          product_id: id,
        },
        Bulkschema,
        setErrors,
        (value: any) => {
          setIsEditable(false)
          const data = {
            ...formik.values.optionDetails[index],
            product_id: id,
          }
          if (formik.values?.optionDetails[index]?.id) {
            Product.editBulk(
              data,
              () => {
                setActiveAdd(true)

                setRowSlectedToEdit(-1)
                setIsCreated(true)
                setIsEditing(false)
              },
              setIsLoading
            )
            // dispatch(
            //   withToastr(updateItemBulkAction(data), {
            //     success: {
            //       title: 'Success to  update  data',
            //     },
            //   })
            // ).then((res) => {
            //   setActiveAdd(true)

            //   setRowSlectedToEdit(-1)
            //   setIsCreated(true)
            //   setIsEditing(false)
            //   toast.success('Modified Successfully')
            // })
          } else {
            Product.addBulk(
              {
                ...formik.values.optionDetails[index],
                product_id: id,
              },
              (res: any) => {
                setActiveAdd(true)
                formik.setFieldValue(
                  'optionDetails',
                  formik.values.optionDetails.map((e: any) => {
                    if (e.max === res.max && e.min === res.min) {
                      return res
                    } else {
                      return e
                    }
                  })
                )

                setRowSlectedToEdit(-1)
                setIsCreated(true)
                setIsEditing(false)
              },
              setIsLoading
            )
          }
        }
      )
    },
    [dispatch, formik.values.optionDetails, id]
  )
  const handleRemove = useCallback(
    (index: number) => {
      //   need  to check  if  bulkDiscount have  includes  index  or  not
      const hasId = formik.values.optionDetails.find((item: any) => item.id == index)
      if (hasId) {
        Product.deleteBulk(
          {
            id: index,
            product_id: id + '',
          },

          () => {
            // formik.values.optionDetails.splice(index, 1)

            formik.setFieldValue(
              'optionDetails',
              formik.values.optionDetails.filter((e: any) => e.id !== index)
            )
            //  setIsCreated(true)
            setIsEditing(true)
            setRowSlectedToEdit(-1)
          },
          setIsLoadingDelete
        )
      }
      //  else  will  just  remove  it  from  formik
      else {
        setActiveAdd(true)
        formik.values.optionDetails.splice(index, 1)
        formik.setFieldValue('optionDetails', formik.values.optionDetails)
        setIsEditing(true)
        setRowSlectedToEdit(-1)
      }
    },
    [bulkDiscount, dispatch, formik, id]
  )
  const [openDelete, setOpenDelete] = useState(false)
  return (
    <div className='row mb-8 g-4   fv-row'>
      <div className='col-lg-6 fv-row fv-plugins-icon-container my-2'>
        <label className='fs-6 fw-semibold form-label mt-3'>
          <span className='required form-label'>{t('minmumQuantity')}</span>
        </label>
        <input
          type='number'
          min={0}
          className='form-control form-control-lg'
          disabled={rowSlectedToEdit === index ? false : true}
          {...formik.getFieldProps(`optionDetails.${index}.min`)}
        />
        <ErrorMsg msgErr={errors['min']} />

        {formik.touched.optionDetails?.[index]?.min && formik.errors.optionDetails?.[index] && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              {(formik.errors.optionDetails[index] as FormikErrors<BulkDiscount>).min}
            </div>
          </div>
        )}
      </div>
      <div className='col-lg-6 fv-row fv-plugins-icon-container my-2'>
        <label className='fs-6 fw-semibold form-label mt-3'>
          <span className='required form-label'>{t('maximumQuantity')}</span>
        </label>

        <input
          type='number'
          min={0}
          className='form-control form-control-lg'
          disabled={rowSlectedToEdit === index ? false : true}
          {...formik.getFieldProps(`optionDetails.${index}.max`)}
        />
        <ErrorMsg msgErr={errors['max']} />

        {formik.touched.optionDetails?.[index]?.max && formik.errors.optionDetails?.[index] && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              {(formik.errors.optionDetails[index] as FormikErrors<BulkDiscount>).max}
            </div>
          </div>
        )}
      </div>
      <div className='col-lg-6 fv-row fv-plugins-icon-container my-2'>
        <label className='fs-6 fw-semibold form-label mt-3'>
          <span className='required form-label'>{t('discountType')}</span>
        </label>

        {/*  is  fixed is  slect  yes  or  no */}
        <select
          className='form-select form-control form-control-lg'
          style={{padding: '0.948rem 3rem 0.775rem 1rem !important'}}
          disabled={rowSlectedToEdit === index ? false : true}
          {...formik.getFieldProps(`optionDetails.${index}.is_fixed`)}
        >
          <option value='1'>{t('fixed')}</option>
          <option value='0'>{t('percentage')}</option>
        </select>
        {formik.touched.optionDetails?.[index]?.is_fixed && formik.errors.optionDetails?.[index] && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              {(formik.errors.optionDetails[index] as FormikErrors<BulkDiscount>).is_fixed}
            </div>
          </div>
        )}
      </div>
      <div className='col-lg-6 fv-row fv-plugins-icon-container my-2'>
        <label className='fs-6 fw-semibold form-label mt-3'>
          <span className='required form-label'>{t('amountValue')}</span>
        </label>

        {/*  is  fixed is  slect  yes  or  no */}
        <input
          type='number'
          min={0}
          className='form-control form-control-lg'
          disabled={rowSlectedToEdit === index ? false : true}
          {...formik.getFieldProps(`optionDetails.${index}.value`)}
        />
        <ErrorMsg msgErr={errors['value']} />

        {formik.touched.optionDetails?.[index]?.value && formik.errors.optionDetails?.[index] && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              {(formik.errors.optionDetails[index] as FormikErrors<BulkDiscount>).value}
            </div>
          </div>
        )}
      </div>
      <div className='col-lg-12 fv-row fv-plugins-icon-container my-2'>
        <div className='d-flex justify-content-end'>
          <div className='col-lg-6 text-end justify-content-end align-items-end d-flex gap-4'>
            {isLoadingDelete ? (
              <SimpleLoading />
            ) : (
              <button
                type='button'
                className='btn btn-icon btn-danger btn-sm'
                onClick={() => setOpenDelete(true)}
              >
                <i className='bi bi-trash fs-7'></i>
              </button>
            )}

            <DeletedMsg
              isLoadingDelete={isLoadingDelete}
              open={openDelete}
              setOpen={setOpenDelete}
              onClick={() => {
                handleRemove(formik.values.optionDetails[index].id || index)
              }}
            />

            {rowSlectedToEdit === index ? (
              <>
                {isLoading ? (
                  <SimpleLoading />
                ) : (
                  <button
                    type='button'
                    className='btn btn-icon btn-success btn-sm me-1'
                    onClick={() => {
                      handelSaveAndUpdate(index)
                    }}
                  >
                    <i className='bi bi-check fs-7'></i>
                  </button>
                )}
              </>
            ) : (
              <button
                className='btn btn-icon btn-light btn-sm'
                type='button'
                onClick={() => {
                  setRowSlectedToEdit(index)
                  setIsEditing(index === rowSlectedToEdit ? false : true)
                  setIsCreated(false)
                  setActiveAdd(false)
                }}
                // disabled={!isEditable}
              >
                <i className='bi bi-pencil-fill fs-7'></i>
              </button>
            )}
          </div>
        </div>
      </div>
      {/* <ErrorMsg msgErr={msgErr} name='' /> */}
    </div>
  )
}
