import {Stack, Typography} from '@mui/material'
import React, {useState} from 'react'
import LabelInput from '../../customTable/LabelInput'
import CheckIcon from '@mui/icons-material/Check'
import {useTranslation} from 'react-i18next'
import Button from '@mui/material/Button'
import Snackbar, {SnackbarCloseReason} from '@mui/material/Snackbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Alert from '@mui/material/Alert'

export default function CheckBox({isCheck, setIsCheck, defLang, options}: any) {
  const {t} = useTranslation()

  const checkBox = [
    {name: t('enable'), value: 1},
    {name: t('disable'), value: 0},
  ]
  const [open, setOpen] = React.useState(false)

  const handleClick = () => {
    setOpen(true)
  }

  const handleClose = (event: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }
  const action = (
    <React.Fragment>
      {/* <Button color='secondary' size='small' onClick={handleClose}>
        UNDO
      </Button> */}
      <IconButton size='small' aria-label='close' color='inherit' onClick={handleClose}>
        <CloseIcon fontSize='small' />
      </IconButton>
    </React.Fragment>
  )
  return (
    <Stack alignItems={'center'} gap={'24px'} direction={'row'}>
      <label style={{display: 'flex', gap: '5px', alignItems: 'center'}} htmlFor={'CODE'}>
        <LabelInput text={t('defaultLang')} />({defLang})
      </label>
      <Stack gap={'24px'} direction={'row'}>
        {checkBox.map((e, i) => (
          <Stack
            onClick={() => {
              if (e.value === 0) {
                if (options.length > 0) {
                  setIsCheck(e.value)
                } else {
                  setOpen(true)
                }
              } else {
                setIsCheck(e.value)
              }
            }}
            alignItems={'center'}
            gap={'8px'}
            direction={'row'}
          >
            <Stack
              width={'24px'}
              borderRadius={'6px'}
              height={'24px'}
              bgcolor={'var(--bs-app-bg-color)'}
            >
              {isCheck === e.value && (
                <Stack
                  alignItems={'center'}
                  justifyContent={'center'}
                  width={'100%'}
                  height={'100%'}
                  borderRadius={'6px'}
                  bgcolor={'var(--main-color)'}
                >
                  <CheckIcon sx={{color: 'white'}} />
                </Stack>
              )}
            </Stack>
            <Typography sx={{fontSize: '14px'}}>{e.name}</Typography>
          </Stack>
        ))}
      </Stack>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity='warning' variant='filled' sx={{width: '100%'}}>
          {t('youMastAddOneLangugeAtLeast')}{' '}
        </Alert>
      </Snackbar>
      {/* <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}

        message={t('youMastAddOneLangugeAtLeast')}
        action={action}
      /> */}
    </Stack>
  )
}
