import React from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'

export default function TableHead({data}: any) {
  const {t} = useTranslation()

  const headTable = [
    {
      name: t(''),
      class: 'min-w-150px',
    },
    {
      name: t('free'),
      class: 'min-w-150px',
    },
    {
      name: t('standard'),
      class: 'min-w-150px',
    },
    {
      name: t('premium'),
      class: 'min-w-150px',
    },
    {
      name: t('ultimate'),
      class: 'min-w-150px',
    },
  ]
  const {lang, defLang} = useSelector((state: RootState) => state.vender)

  return (
    <thead>
      <tr
        // style={{display:'flex',justifyContent:'space-between'}}
        className='fw-bold text-muted'
      >
        {' '}
        <th
          className='min-w-150px'
          style={{
            textAlign: 'center',
          }}
        ></th>
        {data.map((e: any, i: any) => {
          return (
            <>
              <th
                className='min-w-200px'
                style={{
                  textAlign: 'center',
                  width: 'auto',
                }}
                key={i}
              >
                {e && (e[lang] || e[defLang])}
              </th>
            </>
          )
        })}
      </tr>
    </thead>
  )
}
