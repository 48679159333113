import React, {useEffect} from 'react'
import UploadImage from './UploadImage'
import {Stack} from '@mui/material'
import SizeImage from '../../customTable/SizeImage'

export default function CardImage({setting, text, name, setSeeting, width, hight}: any) {
  const [base64Image, setBase64Image] = React.useState('')
  useEffect(() => {
    setBase64Image(setting[`${name}`])
  }, [setting[`${name}`]])

  return (
    <Stack height='100%'>
      <Stack
        sx={{
          // minWidth: '360px',
          width: {xs: '100%', sm: '100%'},
          height: '100%',
          minHeight: '150px',
          padding: '0',
          borderRadius: '7px',
          background: 'var(--bs-app-header-base-bg-color)',
          border: ' 1px solid #E8E8E8',
        }}
      >
        <UploadImage
          setting={setting}
          setSeeting={setSeeting}
          text={text}
          name={name}
          base64Image={base64Image}
          setBase64Image={setBase64Image}
        />
      </Stack>{' '}
      <Stack height={'15px'}>
        <SizeImage width={width} hight={hight} />
      </Stack>
    </Stack>
  )
}
