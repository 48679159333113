import {Stack, Typography} from '@mui/material'
import React from 'react'
import CardImage from './CardImage'
import {useTranslation} from 'react-i18next'
export default function CardsImage({setting, setSeeting}: any) {
  const {t} = useTranslation()
  return (
    <Stack
      mt='30px'
      direction={'row'}
      gap={{xs: '22px', sm: '22px'}}
      justifyContent={'space-between'}
      flexWrap={'wrap'}
    >
      {' '}
      <Stack
        sx={{
          width: {xs: '100%', sm: 'calc(50% - 11px)', lg: 'calc(24% - 8px)'},
          height: {xlg: '100%'},
        }}
      >
        <CardImage setSeeting={setSeeting} text='favicon' name='favicon' setting={setting} />
        <Typography sx={{textAlign: 'center', marginTop: '18px'}}>{t('favicon')}</Typography>
      </Stack>
      <Stack
        sx={{
          width: {xs: '100%', sm: 'calc(50% - 11px)', lg: 'calc(24% - 8px)'},
          height: {xlg: '100%'},
        }}
      >
        <CardImage
          setSeeting={setSeeting}
          text='FRONT HEADER LOGO'
          name='header_logo'
          setting={setting}
          width={'header_logo_width'}
          hight={'header_logo_height'}
        />
        <Typography sx={{textAlign: 'center', marginTop: '18px'}}>
          {' '}
          {t('frontHeaderLogo')}{' '}
        </Typography>
      </Stack>
      <Stack
        sx={{
          width: {xs: '100%', sm: 'calc(50% - 11px)', lg: 'calc(24% - 8px)'},
          height: {xlg: '100%'},
        }}
      >
        <CardImage
          setSeeting={setSeeting}
          text='FRONT FOOTER LOGO'
          name='footer_logo'
          setting={setting}
          width={'footer_logo_width'}
          hight={'footer_logo_height'}
        />
        <Typography sx={{textAlign: 'center', marginTop: '18px'}}>
          {' '}
          {t('frontFooterLogo')}
        </Typography>
      </Stack>
      <Stack
        sx={{
          width: {xs: '100%', sm: 'calc(50% - 11px)', lg: 'calc(24% - 8px)'},
          height: {xlg: '100%'},
        }}
      >
        <CardImage
          width={'app_logo_width'}
          hight={'app_logo_height'}
          setSeeting={setSeeting}
          text='app LOGO'
          name='app_logo'
          setting={setting}
        />

        <Typography sx={{textAlign: 'center', marginTop: '18px'}}> {t('appLogo')}</Typography>
      </Stack>
    </Stack>
  )
}
