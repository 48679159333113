import * as React from 'react'
import {styled, alpha} from '@mui/material/styles'
import Button from '@mui/material/Button'
import Menu, {MenuProps} from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import EditIcon from '@mui/icons-material/Edit'
import Divider from '@mui/material/Divider'
import ArchiveIcon from '@mui/icons-material/Archive'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {Avatar, Stack} from '@mui/material'
import {useDispatch} from 'react-redux'
import {actions} from '../../../../app/store/vender/Auth/auth.slice'
import {useTranslation} from 'react-i18next'
import Image from '../../../../app/modules/customTable/Image'

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({theme}) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: 'rgb(55, 65, 81)',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}))

export default function LangsMenus({selectedLang, languages, setIsLang}: any) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const dispatch = useDispatch()
  const {i18n, t} = useTranslation()
  return (
    <div>
      <Button
        id='demo-customized-button'
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        variant='contained'
        disableElevation
        onClick={handleClick}
        // endIcon={<KeyboardArrowDownIcon />}
        sx={{
          bgcolor: 'var(--bs-app-footer-bg-color)',
          color: '#1976d2',
          '&:hover': {
            bgcolor: 'var(--bs-app-footer-bg-color)',
          },
        }}
      >
        {selectedLang}
      </Button>
      <StyledMenu
        id='demo-customized-menu'
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {languages.map((l: any) => (
          <MenuItem
            dir='rtl'
            className='menu-item'
            key={l.lang}
            onClick={() => {
              // setLanguage(l.lang)
              localStorage.setItem('lang', JSON.stringify(l))
              dispatch(actions.setLang(l.lang))
              dispatch(actions.setRtl(l.isRtl))
              handleClose()
              i18n.changeLanguage(l.lang)
              setIsLang(l)
            }}
          >
            <a
            // className={'menu-link d-flex px-5', {active: l.lang === islang.lang}}
            >
              <Stack gap='5px' alignItems={'center'} direction={'row'}>
                <span className='symbol symbol-20px'>
                  <Image
                    sx={{width: '15px', height: '15px'}}
                    className='rounded-1'
                    src={l.flag}
                    alt={l.name}
                  />
                </span>
                <Stack alignItems={'flex-end'}>{t(l.name)}</Stack>
              </Stack>
            </a>
          </MenuItem>
        ))}
      </StyledMenu>
    </div>
  )
}
