import React, {useState} from 'react'
import {Stack} from '@mui/material'

import {RootState} from '../../../store'
import {useSelector} from 'react-redux'
import SimpleLoading from '../../customTable/SimpleLoading'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useTranslation} from 'react-i18next'
import PopUp from '../../customTable/PopUp'
import HasNotFeature from '../../../svg/HasNotFeature'

export default function Row({item, i}: any) {
  const [open, setOpen] = React.useState(false)
  const [editItem, setEditItem] = useState(item)
  const handelOpen = () => {
    setOpen(true)
  }

  const [isDeleted, setDeleted] = useState(true)

  const {lang, defLang} = useSelector((state: RootState) => state.vender)
  const {t} = useTranslation()

  return (
    <>
      {isDeleted && (
        <>
          {item.name !== 'name' && (
            <tr className='fw-bold text-muted '>
              {item.name !== 'name' && (
                <td style={{textAlign: 'start'}}>
                  {(item.name && (item.name[lang] || item.name[defLang])) || t(item.name)}
                </td>
              )}
              {Object.entries(item).map(([key, value]: any, i) => {
                return (
                  <>
                    <td style={{textAlign: 'center'}}>
                      {/* تأكد من تمرير عنصر نصي أو قيمة واحدة فقط */}
                      {key !== 'name' && key !== 'color' ? (
                        <a className='text-dark fw-bold text-hover-primary fs-6'>
                          {typeof value === 'object' ? (
                            value.hasFeature === true ? (
                              value.amount ? (
                                value.amount
                              ) : (
                                'unlimit'
                              )
                            ) : (
                              <HasNotFeature />
                            )
                          ) : (
                            value.toString()
                          )}
                        </a>
                      ) : null}
                    </td>
                  </>
                )
              })}
            </tr>
          )}
        </>
      )}
    </>
  )
}
