import {Stack} from '@mui/material'
import React from 'react'

export default function NewStatusOrder({text, state}: any) {
  return (
    <Stack
      justifyContent={'center'}
      height={'100%'}
      sx={{
        textAlign: 'center',
        padding: state ? '5px 6px' : '12.0625px 13px 12.5px',
        background:
          state && text === 'pending'
            ? '#FFC700'
            : state && text === 'preparing'
            ? '#7239EA'
            : state && text === 'in_progress'
            ? '#009EF7'
            : state && text === 'refunded'
            ? '#2e63ff'
            : state && text === 'not_active'
            ? '#5959597a'
            : state && (text === 'completed' || text === 'active')
            ? '#50CD89'
            : state && (text === 'canceled' || text === 'blocked')
            ? 'red'
            : 'var(--bs-body-bg)',
        border: state ? 'none' : 'auto',
        color: '#fff',
        borderRadius: '8.18px',
        height: '100%',
        width: '100%',
        '& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input':
          {
            paddingRight: '0px !important',
          },
        '& .css-1k0sxlf': {
          padding: state ? '5px 6px !important' : '12.0625px 13px 12.5px !important',
        },
        '& fieldset.MuiOutlinedInput-notchedOutline': {
          border: 'none !important',
        },
        '& .MuiSelect-icon': {
          right: '10px', // لإزالة المسافة الافتراضية من اليمين
          color: '#0000',
        },
      }}
    >
      {text}
    </Stack>
  )
}
