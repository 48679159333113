import clsx from 'clsx'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {HeaderNotificationsMenu, HeaderUserMenu, Search, ThemeModeSwitcher} from '../../../partials'
import {useLayout} from '../../core'
import {Avatar, Badge, Stack} from '@mui/material'
import SelectLangs from '../../../../app/modules/login/SelectLang'
import NotificationsNoneIcon from '../../../../app/svg/NotificationsNoneIcon'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../app/store'
import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import Image from '../../../../app/modules/customTable/Image'

const itemClass = 'ms-1 ms-lg-3'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'svg-icon-1'

const Navbar = () => {
  const {config} = useLayout()
  const image = localStorage.getItem('image')
  const language = navigator.language

  const store = useSelector((state: RootState) => state.vender)

  const [languages, setLangs] = useState<any>(
    store.langs.map((e: any) => {
      return {
        id: e.id,
        lang: e.code,
        name: e.lang,
        flag: e.flag,
        isRtl: e.is_rtl,
      }
    })
  )

  useEffect(() => {
    setLangs(
      store.langs.map((e: any) => {
        return {
          id: e.id,
          lang: e.code,
          name: e.lang,
          flag: e.flag,
          isRtl: e.is_rtl,
        }
      })
    )
  }, [store.langs])
  const myLang = languages.find((e: any) => e.lang === language.split('-')[0])
  const {sizeImages} = useSelector((state: any) => state.vender)

  const navigation = useNavigate()

  return (
    <Stack alignItems={'center'} gap='15px' direction={'row'} className='app-navbar flex-shrink-0'>
      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div> */}
      {languages.length > 0 && (
        <Stack display={{md: 'block', xs: 'none'}}>
          <SelectLangs
            value={{
              id:
                localStorage.getItem('lang') &&
                languages.some(
                  (e: any) => e.id === +JSON.parse(localStorage.getItem('lang') || `{}`).id
                )
                  ? +JSON.parse(localStorage.getItem('lang') || `{id: 1}`).id
                  : -1,
            }}
            option={languages}
            color={'var(--bs-app-footer-bg-color)'}
          />
        </Stack>
      )}

      <Stack
        sx={{cursor: 'pointer'}}
        onClick={() => {
          navigation('/helpdesk/notification?type=in')
        }}
        position={'relative'}
      >
        <NotificationsNoneIcon />

        {sizeImages && sizeImages.notification && sizeImages.notification.is_new_notification && (
          <Stack
            position='absolute'
            width='9px'
            height={'9px'}
            borderRadius={'50%'}
            bgcolor={'var(--orange-color)'}
            border='1px solid #fff'
            right={'-2px'}
          ></Stack>
        )}
      </Stack>
      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <Image
            sx={{borderRadius: '50%', width: '50px'}}
            src={image || '/media/avatars/300-1.jpg'}
            alt=''
          />
        </div>
        <HeaderUserMenu languages={languages} />
      </div>
    </Stack>
  )
}

export {Navbar}
