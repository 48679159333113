import {Stack} from '@mui/material'
import React from 'react'
import Error503Icon from '../../../svg/Error503Icon'
import Label from '../../customTable/Label'

export default function Error503() {
  return (
    <Stack gap='24px' alignItems={'center'} justifyContent={'center'} width='100%' height={'70vh'}>
      <Error503Icon />
      <Stack gap='16px' alignItems={'center'} justifyContent={'center'}>
        <Label fs='16px' fw='600' align='center' text='503ServiceUnavailable' />
        <Stack maxWidth={'476px'}>
          <Label fw='400' fs='14px' align='center' text='503ServiceUnavailableMassage' />
        </Stack>
      </Stack>
    </Stack>
  )
}
