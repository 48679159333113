import {Stack, Typography} from '@mui/material'
import React, {useEffect, useState} from 'react'
import SelectLangs from './SelectLang'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {Navbar} from './Navbar'
import {useTranslation} from 'react-i18next'
import MainBtn from '../customTable/MainBtn'
import Langs from '../../Api/SettingLangs'

export default function GetStart() {
  const languages = [
    {
      id: 1,
      lang: 'en',
      name: 'English',
      flag: toAbsoluteUrl('/media/flags/united-states.svg'),
      isRtl: 0,
    },
    {
      id: 2,
      lang: 'ar',
      name: 'العربيه',
      flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f6/Flag_of_Iraq.svg/220px-Flag_of_Iraq.svg.png',
      isRtl: 1,
    },
    {
      id: 3,
      lang: 'ku',
      name: 'Kurdish',
      flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/35/Flag_of_Kurdistan.svg/280px-Flag_of_Kurdistan.svg.png',
      isRtl: 1,
    },
  ]
  const [langs, setLangs] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    Langs.getAllLangs((res: any) => {
      setLangs(
        res.data.map(
          (e: any) => (e = {...e, name: e.lang, lang: e.code, isRtl: e.is_rtl, id: e.id})
        )
      )
    }, setIsLoading)
  }, [])
  const {t} = useTranslation()
  const language = navigator.language
  const myLang: any = langs.find((e: any) => e.lang === language.split('-')[0])

  return (
    <Stack alignItems={'center'} gap={{md: '46px', xs: '20px'}} direction={'row'}>
      <Stack display={{md: 'block', xs: 'none'}} width={'120px'}>
        <SelectLangs
          value={{
            id: localStorage.getItem('lang')
              ? +JSON.parse(localStorage.getItem('lang') || `{id: 1}`).id
              : myLang
              ? myLang.id
              : 1,
          }}
          option={langs}
          color={'var(--bs-app-bg-color)'}
        />
      </Stack>
      <Typography sx={{fontSize: '14px', fontWeight: '600', display: {md: 'block', xs: 'none'}}}>
        {t('login')}
      </Typography>
      <a
        href='https://www.koninstore.com/ar/create-store/first-step'
        style={{
          border: 'none',
        }}
      >
        <MainBtn br='8px' bgcolor='#3C3AF7' text={'getStarted'} handelSubmit={() => {}} />
      </a>
      <Stack display={{md: 'none', xs: 'block'}}>
        <Navbar langs={langs} />
      </Stack>
    </Stack>
  )
}
