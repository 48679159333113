import React, {useEffect, useState} from 'react'
import RedStart from '../../../../customTable/RedStart'
import {Box, Container, Stack, Typography} from '@mui/material'
import InputType from '../../../../customTable/InputType'
import ErrorMsg from '../../../../customTable/ErrorMsg'
import SaveButton from '../../../../customTable/SaveButton'
import MySelect from '../../../../customTable/CustomSelectTest'
import SelectByLang from '../../../../customTable/SelectByLang'
import UploadImg from '../../../../customTable/UploadImg'
import * as Yup from 'yup'
import {OptionValueschema} from '../../../../../yup/OptionValue'
import Product from '../../../../../Api/product'
import {useParams} from 'react-router-dom'
import Loading from '../../../../customTable/LoadingForm'
import {useTranslation} from 'react-i18next'
import TabsLangWithFlag from '../../../../customTable/LangTabs'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../../store'
import SetLang from '../../../../../utils/SetLangs'
import ValidationFun from '../../../../../yup/ValidationFun'

export default function EditOptionForm({
  item,
  values,
  type,
  setOpen,
  optionValue,
  setOptionValue,
  open,
  option_id,
  setEditItem,
  valuesId,
  isSelected,
  setIsSelected,
  options,
}: any) {
  const {id} = useParams()

  const {t} = useTranslation()
  let languages = SetLang() || ['en']
  const [errors, setErrors] = useState<any>({})
  const handelEdit = () => {
    ValidationFun(
      {
        ...option,
        price_action: option.price_action === 1 ? '+' : '-',
        option_image: base64Image,
        isImage: type === 'text' ? 0 : values.is_upload_image,
        option_value_id:
          item.id && item.option_detail_id === ''
            ? 0
            : item.id && item.option_detail_id !== ''
            ? item.option_detail_id
            : item.option_value_id === ''
            ? 0
            : item.option_value_id,
      },
      OptionValueschema(languages),
      setErrors,
      () => {
        if (item.id) {
          Product.editOptionProduct(
            item.id,
            id,
            valuesId,
            {
              ...{
                ...option,
                price_action: option.price_action === 1 ? '+' : '-',
                option_image: base64Image,
                isImage: type === 'text' ? 0 : values.is_upload_image,
                option_value_id:
                  item.id && item.option_detail_id === ''
                    ? 0
                    : item.id && item.option_detail_id !== ''
                    ? item.option_detail_id
                    : item.option_value_id === ''
                    ? 0
                    : item.option_value_id,
              },
              option_image: base64Image.includes('data') ? base64Image : '',
              option_value_id: selectData.id !== -1 ? selectData.id : option.option_detail_id,
            },
            (res: any) => {
              if (selectData.id !== -1) {
                setIsSelected([
                  ...isSelected.filter((e: any) => e !== item.option_detail_id),
                  selectData.id,
                ])
              }

              setEditItem({
                ...res,
              })
            },
            setUsLoading
          )
        } else {
          if (selectData.id !== -1) {
            setIsSelected([
              ...isSelected.filter((e: any) => e !== option.option_value_local.id),
              selectData.id,
            ])
          }
          setEditItem({
            ...option,
            price_action: option.price_action === 1 ? '+' : '-',
            option_image: base64Image,
            option_value_local: selectData.id !== -1 ? selectData : option.option_value_local,
          })

          setOptionValue([
            ...optionValue.map((e: any) => {
              if (e.idDate !== item.idDate) {
                return e
              } else {
                return {
                  ...option,
                  price_action: option.price_action === 1 ? '+' : '-',
                  option_image: base64Image,
                  // idDate: Date.now(),
                }
              }
            }),
          ])
          setUsLoading(true)
          setTimeout(() => {
            setUsLoading(false)
          }, 1)
        }

        setOpen(false)
      }
    )
    // OptionValueschema(languages)
    //   .validate({
    //     ...option,
    //     price_action: option.price_action === 1 ? '+' : '-',
    //     option_image: base64Image,
    //     isImage: type === 'text' ? 0 : values.is_upload_image,
    //     option_value_id:
    //       item.id && item.option_detail_id === ''
    //         ? 0
    //         : item.id && item.option_detail_id !== ''
    //         ? item.option_detail_id
    //         : item.option_value_id === ''
    //         ? 0
    //         : item.option_value_id,
    //   })
    //   .then(async (data: any) => {
    //     try {
    //       if (item.id) {
    //         const updateOption = await Product.editOptionProduct(
    //           item.id,
    //           id,
    //           valuesId,
    //           {
    //             ...data,
    //             option_image: base64Image.includes('data') ? base64Image : '',
    //             option_value_id: selectData.id !== -1 ? selectData.id : option.option_detail_id,
    //           },
    //           (res: any) => {
    //             if (selectData.id !== -1) {
    //               setIsSelected([
    //                 ...isSelected.filter((e: any) => e !== item.option_detail_id),
    //                 selectData.id,
    //               ])
    //             }

    //             setEditItem({
    //               ...res,
    //             })
    //           },
    //           setUsLoading
    //         )
    //       } else {

    //         if (selectData.id !== -1) {
    //           setIsSelected([
    //             ...isSelected.filter((e: any) => e !== option.option_value_local.id),
    //             selectData.id,
    //           ])
    //         }
    //         setEditItem({
    //           ...option,
    //           price_action: option.price_action === 1 ? '+' : '-',
    //           option_image: base64Image,
    //           option_value_local: selectData.id !== -1 ? selectData : option.option_value_local,
    //         })

    //         setOptionValue([
    //           ...optionValue.map((e: any) => {
    //             if (e.idDate !== item.idDate) {
    //               return e
    //             } else {

    //               return {
    //                 ...option,
    //                 price_action: option.price_action === 1 ? '+' : '-',
    //                 option_image: base64Image,
    //                 // idDate: Date.now(),
    //               }
    //             }
    //           }),
    //         ])
    //         setUsLoading(true)
    //         setTimeout(() => {

    //           setUsLoading(false)
    //         }, 1)
    //       }

    //       setOpen(false)
    //     } catch (error: any) {
    //       setMsgErr(error.response.data.message)
    //       setUsLoading(false)
    //     }
    //   })
    //   .catch((error: Yup.ValidationError) => {
    //     setMsgErr(error.message)
    //     setUsLoading(false)
    //   })
  }

  const [msgErr, setMsgErr] = React.useState<string>('')
  const [isLoading, setUsLoading] = useState(false)
  const [base64Image, setBase64Image] = React.useState(
    item.option_image || (item.option_detail && item.option_detail.option_image) || ''
  )
  const [isActive, setIsActive] = React.useState(0)
  const [option, setOption] = React.useState({
    ...item,
    price_action: item.price_action === '+' ? 1 : 2,
    price_value: item.price_value ? item.price_value : '0',
  })
  useEffect(() => {
    if (item.option_value || (item.option_detail && item.option_detail.option_value)) {
      setOption({
        ...option,
        option_value: item.option_value
          ? item.option_value
          : item.option_detail && item.option_detail.option_value
          ? item.option_detail.option_value
          : '',
      })
    }
  }, [])

  const [selectData, setSelectData] = React.useState({id: -1})
  const handelselctions = () => {
    if (item.option_detail_id) {
      setIsSelected([...isSelected, item.option_detail_id])
    }
  }
  const [selectedLang, setSelectedLang] = useState<any>([])
  const store = useSelector((state: RootState) => state.vender)
  const [defLang, setDefLang] = useState('')

  const getLangs = async () => {
    setSelectedLang(store.langs)
    setDefLang(
      store.langs.some((e: any) => e.code === store.defLang) ? store.defLang : store.langs[0].code
    )
  }
  useEffect(() => {
    getLangs()
  }, [store.langs])
  const currency = localStorage.getItem('currency')

  return (
    <Container
      lang={store.lang}
      dir={store.isRtl === 0 ? 'ltr' : 'rtl'}
      sx={{padding: '31px 24px', position: 'relative'}}
      maxWidth='md'
    >
      <p style={{width: '500px'}}></p>

      <Stack width='100%'>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: '19px',
            color: 'var(--bs-body-color)',
            lineHeight: '24px',
          }}
        >
          {t('editOption')}
        </Typography>
        <Stack gap='30px' mt='32px'>
          {type !== 'text' && values.is_upload_image !== 0 && (
            <Stack gap={'12px'}>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: '16px',
                  color: 'var(--bs-body-color)',
                  lineHeight: '16px',
                }}
              >
                {' '}
                {t('image')}
              </Typography>
              <Stack
                width='80px'
                height={'80px'}
                sx={{
                  boxShadow: '0px 6.5px 19.5px 6.5px rgba(0, 0, 0, 0.075)',
                  borderRadius: '7px',
                }}
              >
                <UploadImg
                  errors={errors}
                  name='option_image'
                  base64Image={base64Image}
                  setBase64Image={setBase64Image}
                />
              </Stack>
              <ErrorMsg msgErr={msgErr} name='image' />
            </Stack>
          )}

          {type !== 'text' && values.is_upload_image !== 0 && (
            <Stack position={'relative'} gap={'12px'}>
              <Stack direction={'row'} gap='5px'>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: '16px',
                    color: 'var(--bs-body-color)',
                    lineHeight: '16px',
                  }}
                >
                  {' '}
                  {t('optionName')}
                </Typography>
                <RedStart />
              </Stack>
              <Box width='100%'>
                {selectedLang.map((e: any, i: number) => (
                  <>
                    {e.code === defLang && (
                      <>
                        <Stack key={i} sx={{width: {xs: '100%'}}} gap='10px'>
                          <Box sx={{width: {sm: '100%', xs: '100%'}}}>
                            <InputType
                              errors={errors}
                              child={e.code}
                              name={'option_value'}
                              change={setOption}
                              value={option}
                              type='text'
                            />
                          </Box>
                        </Stack>
                      </>
                    )}
                  </>
                ))}
              </Box>
              <ErrorMsg msgErr={msgErr} name='Option value' />

              <Stack position={'absolute'} top={'25px'} right={'0'}>
                <TabsLangWithFlag
                  activeLang={defLang}
                  setActiveLang={setDefLang}
                  languages={selectedLang}
                />
              </Stack>
            </Stack>
          )}
          {(type === 'text' || values.is_upload_image === 0) && open && (
            <>
              <SelectByLang
                setSelectData={setSelectData}
                value={item.id ? item.option_detail_id : item.option_value_id}
                values={option}
                name={'option_value_id'}
                option={
                  isSelected
                    ? options.filter(
                        (e: any) =>
                          (!isSelected.includes(e.id) && e.id !== item.id) ||
                          e.id === item.option_value_id ||
                          e.id === item.option_detail_id
                      )
                    : options
                }
              />
              <ErrorMsg msgErr={msgErr} name='option_value_id' />
            </>
          )}
          <Stack gap={'12px'}>
            <Stack direction={'row'} gap='5px'>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: '16px',
                  color: 'var(--bs-body-color)',
                  lineHeight: '16px',
                }}
              >
                {t('priceOpertion')}
              </Typography>
              <RedStart />
            </Stack>
            <Box width='100%'>
              <Stack
                className='select-with-input'
                direction={'row'}
                sx={{
                  width: {xs: '100%'},
                  gap: '10px',
                  background: 'var(--bs-body-bg)',
                  border: '1px solid var(--bs-gray-300)',
                }}
              >
                <Box sx={{width: {sm: '50%', xs: '50%'}}}>
                  <MySelect
                    option={[
                      {name: '+', id: 1},
                      {name: '-', id: 2},
                    ]}
                    values={option}
                    setValues={setOption}
                    name='price_action'
                    value={{id: item.price_action === '+' ? 1 : 2}}
                    withoutBorder={true}
                  />
                </Box>
                <Box sx={{width: '1px', height: '43px', background: 'var(--bs-gray-300)'}}></Box>

                <Box position={'relative'} sx={{width: {sm: '50%', xs: '50%'}}}>
                  <InputType
                    errors={errors}
                    withoutBorder={true}
                    name={'price_value'}
                    change={setOption}
                    value={option}
                    type='number'
                  />
                  <Stack
                    sx={{
                      background: 'var(--bs-text-muted)',
                      top: '0',
                      right: store.isRtl === 0 ? 0 : 'auto',
                      left: store.isRtl !== 0 ? 0 : 'auto',
                      height: '100%',
                      width: '60px',
                      borderRadius: '3px',
                    }}
                    alignItems={'center'}
                    justifyContent={'center'}
                    position={'absolute'}
                  >
                    <Typography sx={{color: 'white', fontSize: '16px'}}>{currency}</Typography>
                  </Stack>
                </Box>
              </Stack>
              <ErrorMsg msgErr={msgErr} name='price' />
            </Box>
          </Stack>

          <Stack gap={'12px'}>
            <Stack direction={'row'} gap='5px'>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: '16px',
                  color: 'var(--bs-body-color)',
                  lineHeight: '16px',
                }}
              >
                {t('sort')}
              </Typography>
              {/* <RedStart /> */}
            </Stack>
            <Box width='100%'>
              <Box sx={{width: '100%'}}>
                <InputType
                  errors={errors}
                  name={'sort'}
                  change={setOption}
                  value={option}
                  type='number'
                />
              </Box>
            </Box>

            <ErrorMsg msgErr={msgErr} name='sort' />
          </Stack>
        </Stack>
        <SaveButton
          handelselctions={handelselctions}
          text={t('save')}
          submit={handelEdit}
          isLoading={false}
          setOpen={setOpen}
        />
      </Stack>
      <Stack alignItems={'flex-end'}>
        <ErrorMsg msgErr={msgErr} name='' />
      </Stack>
      {isLoading && <Loading />}
    </Container>
  )
}
