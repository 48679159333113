import {Box, Stack} from '@mui/material'
import React, {useState, useEffect} from 'react'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import TabsWithFlag from '../../customTable/LangTabsWithFlag'
import LabelInput from '../../customTable/LabelInput'
import RedStart from '../../customTable/RedStart'
import InputType from '../../customTable/InputType'
import {useTranslation} from 'react-i18next'
import TextErea from '../../customTable/TextErea'
import Editor from '../../customTable/Editor'
import EditorText from '../../customTable/EditorText'
import Switch from '../../../componet/Toastr/Switch'
import ErrorMsg from '../../customTable/ErrorMsg'
import EditorM from '../../customTable/EditorM'
import MySelect from '../../customTable/CustomSelectTest'

export default function InputsText({page, setPage, msgErr, setMsgErr, test, setTest, errors}: any) {
  const store = useSelector((state: RootState) => state.vender)
  const {t} = useTranslation()
  const [state, setState] = useState(page.status)

  const [defLang, setDefLang] = useState('')
  const [selectedLang, setSelectedLang] = useState<any>([])

  const getLangs = async () => {
    setSelectedLang(store.langs)
    setDefLang(
      store.langs.some((e: any) => e.code === store.defLang) ? store.defLang : store.langs[0].code
    )
  }
  useEffect(() => {
    getLangs()
  }, [store.langs])

  const handleStatusChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    // Media.putStatus(item.id, state === 1 ? 0 : 1)
    setPage({...page, status: state === 1 ? 0 : 1})
    setState(state === 1 ? 0 : 1)
  }

  return (
    <Stack p='24px' width='100%'>
      <Stack alignItems={'flex-end'}>
        <TabsWithFlag
          languages={selectedLang}
          activeLang={defLang}
          setActiveLang={setDefLang}
          msgErr={errors && Object.keys(errors)[0]}
        />
      </Stack>
      <Stack mt='24px'>
        {selectedLang.map((e: any, i: number) => (
          <>
            {e.code === defLang && (
              <Stack gap='24px'>
                <Stack width={'100%'} gap={'15px'} direction={{sm: 'row', xs: 'column'}}>
                  <Stack width={'100%'} gap='10px'>
                    <label style={{display: 'flex', gap: '5px'}} htmlFor={e.code}>
                      <LabelInput text={t('title')} />
                      <RedStart />
                    </label>
                    <Box position={'relative'} sx={{width: {sm: '100%', xs: '100%'}}}>
                      <InputType
                        name={'title'}
                        change={setPage}
                        value={page}
                        type='text'
                        child={e.code}
                        otherName='permalink'
                        errors={errors}
                      />
                    </Box>
                  </Stack>

                  <Stack width={'100%'} gap='10px'>
                    <label style={{display: 'flex', gap: '5px'}} htmlFor={e.code}>
                      <LabelInput text={t('permalink')} />
                      <RedStart />
                    </label>
                    <Box position={'relative'} sx={{width: {sm: '100%', xs: '100%'}}}>
                      <InputType
                        errors={errors}
                        name={'permalink'}
                        change={setPage}
                        value={page}
                        type='text'
                        child={e.code}
                      />
                    </Box>
                  </Stack>
                </Stack>

                <Stack width={'100%'} gap='10px'>
                  <label style={{display: 'flex', gap: '5px'}} htmlFor={e.code}>
                    <LabelInput text={t('pageType')} />
                  </label>
                  <Box position={'relative'} sx={{width: {sm: '100%', xs: '100%'}}}>
                    <MySelect
                      stringValue
                      option={[
                        {name: t('general'), id: 'general'},
                        {name: t('about-us'), id: 'about-us'},
                        {name: t('privacy'), id: 'privacy'},
                        {name: t('terms'), id: 'terms'},
                      ]}
                      values={page}
                      setValues={setPage}
                      name='page_type'
                      value={{id: page.page_type || 'general'}}
                      errors={errors}
                    />
                  </Box>
                </Stack>
                <Stack width={'100%'} gap='10px'>
                  <label style={{display: 'flex', gap: '5px'}} htmlFor={e.code}>
                    <LabelInput text={t('metaTitle')} />
                  </label>
                  <Box position={'relative'} sx={{width: {sm: '100%', xs: '100%'}}}>
                    <InputType
                      name={'meta_title'}
                      change={setPage}
                      value={page}
                      type='text'
                      child={e.code}
                      errors={errors}
                    />
                  </Box>
                </Stack>
                <Stack width={'100%'} gap='10px'>
                  <label style={{display: 'flex', gap: '5px'}} htmlFor={e.code}>
                    <LabelInput text={t('keyword')} />
                    {/* <RedStart /> */}
                  </label>
                  <Box position={'relative'} sx={{width: {sm: '100%', xs: '100%'}}}>
                    <InputType
                      name={'keyword'}
                      change={setPage}
                      value={page}
                      type='text'
                      child={e.code}
                      errors={errors}
                    />
                  </Box>
                </Stack>
                <Stack width={'100%'} gap='10px'>
                  <label style={{display: 'flex', gap: '5px'}} htmlFor={e.code}>
                    <LabelInput text={t('metaDescription')} />
                    {/* <RedStart /> */}
                  </label>
                  <Box position={'relative'} sx={{width: {sm: '100%', xs: '100%'}}}>
                    <TextErea
                      child={e.code}
                      name={'meta_description'}
                      change={setPage}
                      value={page}
                      errors={errors}
                    />
                  </Box>
                </Stack>
                <Stack width={'100%'} gap='10px'>
                  <label style={{display: 'flex', gap: '5px'}} htmlFor={e.code}>
                    <LabelInput text={t('styles')} />
                    {/* <RedStart /> */}
                  </label>
                  <Box position={'relative'} sx={{width: {sm: '100%', xs: '100%'}}}>
                    <TextErea
                      errors={errors}
                      child={e.code}
                      name={'styles'}
                      change={setPage}
                      value={page}
                    />
                  </Box>
                </Stack>
                <Stack width={'100%'} gap='10px'>
                  <label style={{display: 'flex', gap: '5px'}} htmlFor={e.code}>
                    <LabelInput text={t('description')} />
                    <RedStart />
                  </label>
                  <Box position={'relative'} sx={{width: {sm: '100%', xs: '100%'}}}>
                    <EditorM
                      errors={errors}
                      child={e.code}
                      name={'description'}
                      change={setTest}
                      value={test}
                    />
                  </Box>
                </Stack>

                <Stack direction={'row'} width={'100%'} gap='30px'>
                  <label style={{display: 'flex', gap: '5px'}} htmlFor={e.code}>
                    <LabelInput text={t('status')} />
                  </label>
                  <Switch
                    name='status'
                    value={state === 1 ? true : false}
                    handleChange={handleStatusChange}
                  />
                </Stack>
              </Stack>
            )}
          </>
        ))}
      </Stack>
    </Stack>
  )
}
