import React from 'react'

export default function LogoCloud() {
  return (
    <svg width='74' height='30' viewBox='0 0 74 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M69.6425 11.867H67.5645L62.8593 4.75716V11.867H60.7812V1.4917H62.8593L67.5645 8.61635V1.4917H69.6425V11.867Z'
        fill='#04034F'
      />
      <path d='M58.7343 1.50659V11.867H56.6562V1.50659H58.7343Z' fill='#04034F' />
      <path
        d='M54.6035 11.867H52.5254L47.8202 4.75716V11.867H45.7422V1.4917H47.8202L52.5254 8.61635V1.4917H54.6035V11.867Z'
        fill='#04034F'
      />
      <path
        d='M38.8919 11.9709C37.9222 11.9709 37.0316 11.7433 36.2202 11.2881C35.4088 10.8329 34.7656 10.2046 34.2906 9.40307C33.8156 8.59165 33.5781 7.67633 33.5781 6.65711C33.5781 5.64779 33.8156 4.74236 34.2906 3.94084C34.7656 3.12942 35.4088 2.49612 36.2202 2.04093C37.0316 1.58575 37.9222 1.35815 38.8919 1.35815C39.8716 1.35815 40.7621 1.58575 41.5637 2.04093C42.3751 2.49612 43.0133 3.12942 43.4784 3.94084C43.9534 4.74236 44.1909 5.64779 44.1909 6.65711C44.1909 7.67633 43.9534 8.59165 43.4784 9.40307C43.0133 10.2046 42.3751 10.8329 41.5637 11.2881C40.7522 11.7433 39.8617 11.9709 38.8919 11.9709ZM38.8919 10.1155C39.5153 10.1155 40.0645 9.977 40.5395 9.69993C41.0145 9.41296 41.3856 9.00725 41.6527 8.4828C41.9199 7.95835 42.0535 7.34978 42.0535 6.65711C42.0535 5.96444 41.9199 5.36082 41.6527 4.84626C41.3856 4.32181 41.0145 3.92105 40.5395 3.64398C40.0645 3.36691 39.5153 3.22837 38.8919 3.22837C38.2685 3.22837 37.7144 3.36691 37.2295 3.64398C36.7545 3.92105 36.3835 4.32181 36.1163 4.84626C35.8491 5.36082 35.7155 5.96444 35.7155 6.65711C35.7155 7.34978 35.8491 7.95835 36.1163 8.4828C36.3835 9.00725 36.7545 9.41296 37.2295 9.69993C37.7144 9.977 38.2685 10.1155 38.8919 10.1155Z'
        fill='#04034F'
      />
      <path
        d='M30.0903 11.867L26.3202 7.25084V11.867H24.2422V1.50659H26.3202V6.15245L30.0903 1.50659H32.5988L28.324 6.64227L32.7175 11.867H30.0903Z'
        fill='#04034F'
      />
      <path
        d='M68.0748 19.3176C69.1633 19.3176 70.1182 19.5304 70.9395 19.9559C71.7707 20.3814 72.409 20.9899 72.8543 21.7816C73.3095 22.5633 73.5371 23.4737 73.5371 24.5127C73.5371 25.5517 73.3095 26.4621 72.8543 27.2438C72.409 28.0156 71.7707 28.6143 70.9395 29.0398C70.1182 29.4653 69.1633 29.6781 68.0748 29.6781H64.4531V19.3176H68.0748ZM68.0006 27.9117C69.0891 27.9117 69.9302 27.6149 70.5239 27.0212C71.1176 26.4274 71.4145 25.5913 71.4145 24.5127C71.4145 23.4341 71.1176 22.593 70.5239 21.9894C69.9302 21.3759 69.0891 21.0691 68.0006 21.0691H66.5311V27.9117H68.0006Z'
        fill='#04034F'
      />
      <path
        d='M56.1561 19.3176V25.7298C56.1561 26.4324 56.3392 26.9717 56.7053 27.3477C57.0715 27.7138 57.586 27.8969 58.249 27.8969C58.9219 27.8969 59.4414 27.7138 59.8075 27.3477C60.1737 26.9717 60.3567 26.4324 60.3567 25.7298V19.3176H62.4496V25.715C62.4496 26.5957 62.2566 27.3427 61.8707 27.9563C61.4947 28.5599 60.9851 29.0151 60.3419 29.3218C59.7086 29.6286 59.0011 29.782 58.2193 29.782C57.4475 29.782 56.7449 29.6286 56.1116 29.3218C55.4882 29.0151 54.9934 28.5599 54.6273 27.9563C54.2612 27.3427 54.0781 26.5957 54.0781 25.715V19.3176H56.1561Z'
        fill='#04034F'
      />
      <path
        d='M47.2591 29.7819C46.2894 29.7819 45.3988 29.5544 44.5874 29.0992C43.776 28.644 43.1328 28.0156 42.6578 27.2141C42.1828 26.4027 41.9453 25.4874 41.9453 24.4681C41.9453 23.4588 42.1828 22.5534 42.6578 21.7519C43.1328 20.9405 43.776 20.3072 44.5874 19.852C45.3988 19.3968 46.2894 19.1692 47.2591 19.1692C48.2388 19.1692 49.1293 19.3968 49.9309 19.852C50.7423 20.3072 51.3805 20.9405 51.8456 21.7519C52.3206 22.5534 52.5581 23.4588 52.5581 24.4681C52.5581 25.4874 52.3206 26.4027 51.8456 27.2141C51.3805 28.0156 50.7423 28.644 49.9309 29.0992C49.1194 29.5544 48.2289 29.7819 47.2591 29.7819ZM47.2591 27.9266C47.8825 27.9266 48.4317 27.788 48.9067 27.511C49.3817 27.224 49.7527 26.8183 50.0199 26.2938C50.2871 25.7694 50.4207 25.1608 50.4207 24.4681C50.4207 23.7755 50.2871 23.1719 50.0199 22.6573C49.7527 22.1328 49.3817 21.7321 48.9067 21.455C48.4317 21.1779 47.8825 21.0394 47.2591 21.0394C46.6357 21.0394 46.0816 21.1779 45.5967 21.455C45.1217 21.7321 44.7506 22.1328 44.4835 22.6573C44.2163 23.1719 44.0827 23.7755 44.0827 24.4681C44.0827 25.1608 44.2163 25.7694 44.4835 26.2938C44.7506 26.8183 45.1217 27.224 45.5967 27.511C46.0816 27.788 46.6357 27.9266 47.2591 27.9266Z'
        fill='#04034F'
      />
      <path d='M37.7186 28.0305H41.1325V29.6781H35.6406V19.3176H37.7186V28.0305Z' fill='#04034F' />
      <path
        d='M23.7422 24.4828C23.7422 23.4636 23.9698 22.5532 24.425 21.7517C24.89 20.9403 25.5184 20.3119 26.31 19.8666C27.1116 19.4114 28.0071 19.1838 28.9966 19.1838C30.1544 19.1838 31.1686 19.4807 32.0394 20.0744C32.9102 20.6681 33.5188 21.4895 33.8651 22.5384H31.4754C31.2379 22.0436 30.9015 21.6725 30.4661 21.4251C30.0406 21.1777 29.5458 21.0541 28.9818 21.0541C28.3782 21.0541 27.8389 21.1975 27.3639 21.4845C26.8988 21.7616 26.5327 22.1574 26.2655 22.6719C26.0082 23.1865 25.8796 23.7901 25.8796 24.4828C25.8796 25.1656 26.0082 25.7692 26.2655 26.2936C26.5327 26.8082 26.8988 27.209 27.3639 27.4959C27.8389 27.773 28.3782 27.9115 28.9818 27.9115C29.5458 27.9115 30.0406 27.7878 30.4661 27.5405C30.9015 27.2832 31.2379 26.9072 31.4754 26.4124H33.8651C33.5188 27.4712 32.9102 28.2974 32.0394 28.8912C31.1785 29.475 30.1643 29.7669 28.9966 29.7669C28.0071 29.7669 27.1116 29.5443 26.31 29.099C25.5184 28.6438 24.89 28.0154 24.425 27.2139C23.9698 26.4124 23.7422 25.502 23.7422 24.4828Z'
        fill='#04034F'
      />
      <path
        d='M17.3136 28.0145H10.6999C10.6999 25.178 9.5737 22.4576 7.56878 20.4511C5.56386 18.4446 2.8443 17.3163 0.0078125 17.3141V10.7004C2.84573 10.7004 5.56742 9.57303 7.57413 7.56632C9.58085 5.55961 10.7082 2.83792 10.7082 0H17.3219C17.3237 2.73308 16.6776 5.42766 15.4365 7.8627C14.1954 10.2977 12.3947 12.4038 10.1821 14.0083C12.3929 15.6134 14.1918 17.7195 15.4314 20.1541C16.6711 22.5887 17.3161 25.2825 17.3136 28.0145Z'
        fill='url(#paint0_linear_12749_89821)'
      />
      <path
        d='M2.527 28.0164C3.92262 28.0164 5.054 26.885 5.054 25.4894C5.054 24.0938 3.92262 22.9624 2.527 22.9624C1.13137 22.9624 0 24.0938 0 25.4894C0 26.885 1.13137 28.0164 2.527 28.0164Z'
        fill='#03F7F8'
      />
      <defs>
        <linearGradient
          id='paint0_linear_12749_89821'
          x1='1.94863'
          y1='31.6476'
          x2='21.4148'
          y2='-4.02455'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#03F7F8' />
          <stop offset='0.15' stop-color='#0AE1F8' />
          <stop offset='0.46' stop-color='#1BA8F8' />
          <stop offset='0.91' stop-color='#364DF7' />
          <stop offset='1' stop-color='#3C3AF7' />
        </linearGradient>
      </defs>
    </svg>
  )
}
