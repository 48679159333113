import {Box, Stack, Typography} from '@mui/material'
import React, {useEffect, useState} from 'react'
import LabelInput from '../../customTable/LabelInput'
import InputType from '../../customTable/InputType'
import TabsWithOutFlag from '../general/TabsWithOutFlag'
import RedStart from '../../customTable/RedStart'
import Map from './GoogleMapReact'
import RestSaveButton from '../../customTable/RestSaveBtns'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import TabsLangWithFlag from '../../customTable/LangTabs'
import AddressApi from '../../../Api/SettingAddress'
import MySelect from '../../customTable/CustomSelectTest'
import {AddressSettingValidation} from '../../../yup/SettingSchema'
import * as Yup from 'yup'
import ErrorMsg from '../../customTable/ErrorMsg'
import {useTranslation} from 'react-i18next'
import {toast} from 'react-toastify'
import Loading from '../../customTable/LoadingForm'
import ValidationFun from '../../../yup/ValidationFun'

export default function Address() {
  const [setting, setSetting] = useState<any>({})
  const [center, setCenter] = useState({lat: 0, lng: 0})
  const [cities, setCities] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [msgErr, setMsgErr] = React.useState<string>('')
  const {t} = useTranslation()

  const [old, setOld] = useState({})
  const [languages, setlanguages] = useState(['en'])
  const store = useSelector((state: RootState) => state.vender)
  const [errors, setErrors] = useState<any>({})
  useEffect(() => {
    setlanguages(store.storeLangs)
  }, [store.storeLangs])
  const handelSubmit = async () => {
    ValidationFun(
      {
        ...setting,
        longitude: center.lng,
        latitude: center.lat,
      },
      AddressSettingValidation(languages),
      setErrors,
      () => {
        AddressApi.put(
          {
            ...setting,
            longitude: center.lng,
            latitude: center.lat,
          },
          (res: any) => {
            setSetting(res)
            setMsgErr('')

            setOld(res)
          },
          setIsLoading
        )
      }
    )
  }
  const [defLang, setDefLang] = useState('')

  const getLangs = async () => {
    setSelectedLang(store.langs)
    setDefLang(
      store.langs.some((e: any) => e.code === store.defLang) ? store.defLang : store.langs[0].code
    )
  }
  const [isLoadingAddress, setIsLoadingAddress] = useState(false)
  const [isLoadingCities, setLoadingCities] = useState(false)

  const getAddress = async () => {
    AddressApi.get((res: any) => {
      setSetting(res.data)

      setOld(res.data)

      setCenter({lat: +res.data.latitude, lng: +res.data.longitude})
    }, setIsLoadingAddress)
    AddressApi.getCity((res: any) => {
      setCities(res.data)
    }, setLoadingCities)
  }
  useEffect(() => {
    getAddress()
  }, [])
  const [selectedLang, setSelectedLang] = useState<any>([])
  useEffect(() => {
    getLangs()
  }, [store.langs])

  return (
    <>
      {isLoadingAddress ? (
        <Stack position={'relative'} minHeight={'80vh'} width={'100%'}>
          <Loading />
        </Stack>
      ) : (
        <Stack>
          <Stack
            p='24px'
            borderRadius={'8px'}
            bgcolor={'var(--bs-app-header-base-bg-color)'}
            width={'100%'}
          >
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Typography
                sx={{
                  fontSize: '17.5px',
                  color: 'var(--bs-heading-color, inherit)',
                  fontWeight: 600,
                  lineHeight: '16px',
                  textTransform: 'capitalize',
                }}
              >
                {t('address')}
              </Typography>
            </Stack>
            <Stack mt='42px'>
              <Stack gap='20px' flexWrap={'wrap'} direction={'row'}>
                <Stack width={{sm: '48%', xs: '100%'}} gap='10px'>
                  <label style={{display: 'flex', gap: '5px'}}>
                    <LabelInput text={t('country')} />
                  </label>
                  <Box position={'relative'} sx={{width: {sm: '100%', xs: '100%'}}}>
                    <InputType
                      name={'country_name'}
                      change={setSetting}
                      value={setting}
                      type='text'
                      checked
                    />
                  </Box>
                </Stack>
                <Stack width={{sm: '48%', xs: '100%'}} gap='10px'>
                  <label style={{display: 'flex', gap: '5px'}}>
                    <LabelInput text={t('city')} />
                  </label>
                  <Box position={'relative'} sx={{width: {sm: '100%', xs: '100%'}}}>
                    <MySelect
                      values={setting}
                      name={'city_id'}
                      option={cities}
                      setValues={setSetting}
                      value={{id: setting.city_id}}
                    />
                  </Box>
                </Stack>
                <Stack position={'relative'} width={{sm: '48%', xs: '100%'}} gap='10px'>
                  {selectedLang.map((e: any, i: number) => (
                    <>
                      {e.code === defLang && (
                        <>
                          <label style={{display: 'flex', gap: '5px'}} htmlFor={e.code}>
                            <LabelInput text={t('streetAddress')} />
                            <RedStart />
                          </label>
                          <Box position={'relative'} sx={{width: {sm: '100%', xs: '100%'}}}>
                            <InputType
                              name={'street_address'}
                              change={setSetting}
                              value={setting}
                              type='text'
                              child={e.code}
                              errors={errors}
                            />
                          </Box>
                        </>
                      )}
                    </>
                  ))}

                  <Stack
                    position={'absolute'}
                    right={store.isRtl === 0 ? '0' : 'auto'}
                    left={store.isRtl === 1 ? '0' : 'auto'}
                    top='24px'
                  >
                    <TabsLangWithFlag
                      activeLang={defLang}
                      setActiveLang={setDefLang}
                      languages={selectedLang}
                    />
                  </Stack>
                </Stack>
                <Stack position={'relative'} width={{sm: '48%', xs: '100%'}} gap='10px'>
                  <label style={{display: 'flex', gap: '5px'}}>
                    <LabelInput text={t('ZipPostal')} />
                  </label>
                  <Box position={'relative'} sx={{width: {sm: '100%', xs: '100%'}}}>
                    <InputType
                      name={'postal_code'}
                      change={setSetting}
                      value={setting}
                      type='text'
                      errors={errors}
                      // child={e.lang}
                    />
                  </Box>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack mt={'50px'} sx={{width: '100%', height: '60vh'}}>
            <Map center={center} setCenter={setCenter} />
          </Stack>
          <Stack>
            {store.permissions && store.permissions.includes('address_update') && (
              <RestSaveButton
                isLoading={isLoading}
                SaveText={t('save')}
                cancelText={t('rest')}
                submit={handelSubmit}
                cancel={() => setSetting(old)}
              />
            )}
          </Stack>
          <Stack alignItems={'flex-end'}></Stack>
        </Stack>
      )}
    </>
  )
}
