import {Stack, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

export default function TableHead() {
  const {t} = useTranslation()

  const headTable = [
    {
      name: t('invoiceNumber'),
      class: 'min-w-150px',
    },
    {
      name: t('plan'),
      class: 'min-w-150px',
    },
    {
      name: t('price'),
      class: 'min-w-150px',
    },
    {
      name: t('templatesName'),
      class: 'min-w-150px',
    },
    {
      name: t('startDate'),
      class: 'min-w-150px',
    },
    {
      name: t('endDate'),
      class: 'min-w-150px',
    },

    {
      name: t('action'),
      class: 'max-w-120px',
    },
  ]

  return (
    <thead>
      <tr
        // style={{display:'flex',justifyContent:'space-between'}}
        className='fw-bold text-muted'
      >
        {headTable.map((e, i) => (
          <th
            style={{
              textAlign: i === 0 ? 'start' : 'start',
              width: i === headTable.length - 1 ? '110px' : '120px',
            }}
            key={i}
            className={e.class}
          >
            <Stack width={'max-content'}>{e.name}</Stack>
          </th>
        ))}
      </tr>
    </thead>
  )
}
