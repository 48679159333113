import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink} from '../../../_metronic/layout/core'
import {AddCategory} from './addCtogory'
import {AddProduct} from './addProduct'
import {AddSubCategory} from './addSubCtogory'
import CategoryTable from './CategoryTable/CategoryTable'
import ProductTable from './ProductTable/ProductTable'
import {SubCatogory} from './sub-catogory'
import {useTranslation} from 'react-i18next'
import OptionTable from '../options/optionTable/OptionTable'
import {AddOption} from '../options/AddOption'
import Manufacturer from '../manufacturer'
import CouponsTable from '../Coupons/list'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'
import Error403 from '../errors/components/Error403'
import TitlePage from '../customTable/TitlePage'
import ReViewProductTable from './reviewProductTable/ProductTable'
import OptionCatalog from '../optionCatalog'
import WarperBackages from '../customTable/WarperBackages'
import Products from '../products'

//help
const CategoryPage = () => {
  const {t} = useTranslation()
  const store = useSelector((state: RootState) => state.vender)

  return (
    <Routes>
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          path='list'
          element={
            <>
              {store.permissions && store.permissions.includes('categories_read') ? (
                <WarperBackages backageKey='CATEGORY_NUM'>
                  <>
                    <TitlePage
                      info={{
                        title: t('categories'),
                        home: t('home'),
                        linkHome: '/',
                      }}
                    />
                    <CategoryTable />
                  </>{' '}
                </WarperBackages>
              ) : (
                <Error403 />
              )}
            </>
          }
        />
        <Route
          path='add-category'
          element={
            <>
              {store.permissions && store.permissions.includes('categories_create') ? (
                <WarperBackages backageKey='CATEGORY_NUM'>
                  <>
                    <TitlePage
                      info={{
                        title: t('addCategoriess'),
                        home: t('categories'),
                        linkHome: '/catalog/list',
                      }}
                    />
                    <AddCategory />
                  </>{' '}
                </WarperBackages>
              ) : (
                <Error403 />
              )}
            </>
          }
        />
        <Route
          path='edit-category/:id'
          element={
            <>
              {store.permissions && store.permissions.includes('categories_update') ? (
                <WarperBackages backageKey='CATEGORY_NUM'>
                  <>
                    <TitlePage
                      info={{
                        title: t('editCategories'),
                        home: t('categories'),
                        linkHome: '/catalog/list',
                      }}
                    />
                    <AddCategory />
                  </>{' '}
                </WarperBackages>
              ) : (
                <Error403 />
              )}
            </>
          }
        />
        {/*   sub catogory */}
        <Route
          path='add-sub-category/:id'
          element={
            <>
              {store.permissions && store.permissions.includes('categories_create') ? (
                <WarperBackages backageKey='CATEGORY_NUM'>
                  <WarperBackages backageKey='SUBCATEGORY_NUM'>
                    <>
                      <TitlePage
                        info={{
                          title: t('addSubCategories'),
                          home: t('categories'),
                          linkHome: '/catalog/list',
                        }}
                      />
                      <AddSubCategory />
                    </>{' '}
                  </WarperBackages>
                </WarperBackages>
              ) : (
                <Error403 />
              )}
            </>
          }
        />
        <Route
          path='add-sub-category'
          element={
            <>
              {store.permissions && store.permissions.includes('categories_create') ? (
                <WarperBackages backageKey='CATEGORY_NUM'>
                  <WarperBackages backageKey='SUBCATEGORY_NUM'>
                    <>
                      <TitlePage
                        info={{
                          title: t('addSubCategories'),
                          home: t('categories'),
                          linkHome: '/catalog/list',
                        }}
                      />

                      <AddSubCategory />
                    </>{' '}
                  </WarperBackages>
                </WarperBackages>
              ) : (
                <Error403 />
              )}
            </>
          }
        />
        <Route
          path='edit-sub-category/:id'
          element={
            <>
              {store.permissions && store.permissions.includes('categories_update') ? (
                <WarperBackages backageKey='CATEGORY_NUM'>
                  <WarperBackages backageKey='SUBCATEGORY_NUM'>
                    <>
                      <TitlePage
                        info={{
                          title: t('editSubCategories'),
                          home: t('categories'),
                          linkHome: '/catalog/list',
                        }}
                      />

                      <AddSubCategory />
                    </>{' '}
                  </WarperBackages>
                </WarperBackages>
              ) : (
                <Error403 />
              )}
            </>
          }
        />
        <Route
          path='sub-category/:id'
          element={
            <>
              {store.permissions && store.permissions.includes('categories_read') ? (
                <WarperBackages backageKey='CATEGORY_NUM'>
                  <WarperBackages backageKey='SUBCATEGORY_NUM'>
                    <>
                      <SubCatogory />
                    </>{' '}
                  </WarperBackages>
                </WarperBackages>
              ) : (
                <Error403 />
              )}
            </>
          }
        />
        <Route
          path='coupons-list'
          element={
            <>
              {store.permissions && store.permissions.includes('coupons_read') ? (
                <WarperBackages backageKey='COUPON_NUM'>
                  <>
                    <TitlePage
                      info={{
                        title: t('coupons'),
                        home: t('home'),
                        linkHome: '/',
                      }}
                    />

                    <CouponsTable />
                  </>{' '}
                </WarperBackages>
              ) : (
                <Error403 />
              )}
            </>
          }
        />
        {/* <Route
          path=':id'
          element={
            <>
              <PageTitle breadcrumbs={categoryBreadCrumbs}> {t('editaProduct')} </PageTitle>
            </>
          }
        /> */}
        <Route
          path='products'
          element={
            <>
              {store.permissions && store.permissions.includes('products_read') ? (
                <WarperBackages backageKey='PRODUCT_NUM'>
                  <>
                    <Products />
                  </>
                </WarperBackages>
              ) : (
                <Error403 />
              )}
            </>
          }
        />
        <Route
          path='products/review/:id'
          element={
            <>
              {store.permissions && store.permissions.includes('products_read') ? (
                <WarperBackages backageKey='PRODUCT_NUM'>
                  <>
                    <TitlePage
                      info={{
                        title: t('reviewProduct'),
                        home: t('products'),
                        linkHome: '/catalog/products',
                      }}
                    />
                    <ReViewProductTable />
                  </>
                </WarperBackages>
              ) : (
                <Error403 />
              )}
            </>
          }
        />
        {/* zzzz */}

        <Route
          //   NEED  ADD  BOTH   ID AND CURRENT  TABS  IN THE  ROUTE  AND  MAKE IT  OPTIONAL

          path={`add-product`}
          element={
            <>
              {store.permissions && store.permissions.includes('products_create') ? (
                <WarperBackages backageKey='PRODUCT_NUM'>
                  <>
                    <TitlePage
                      info={{
                        title: t('createProduct'),
                        home: t('products'),
                        linkHome: '/products',
                      }}
                    />
                    <AddProduct />
                  </>
                </WarperBackages>
              ) : (
                <Error403 />
              )}
            </>
          }
        />
        <Route
          //   NEED  ADD  BOTH   ID AND CURRENT  TABS  IN THE  ROUTE  AND  MAKE IT  OPTIONAL

          path={`edit-product/:id`}
          element={
            <>
              {store.permissions && store.permissions.includes('products_update') ? (
                <WarperBackages backageKey='PRODUCT_NUM'>
                  <>
                    {' '}
                    <TitlePage
                      info={{
                        title: t('editaProduct'),
                        home: t('products'),
                        linkHome: '/products',
                      }}
                    />
                    <AddProduct />
                  </>
                </WarperBackages>
              ) : (
                <Error403 />
              )}
            </>
          }
        />

        {/* <Route
          path={`add-product/:id/`}
          element={
            <>
              <PageTitle breadcrumbs={categoryBreadCrumbs}>{t('createProduct')}</PageTitle>
              <AddProduct />
            </>
          }
        /> */}
        <Route
          path='option-catalogue'
          element={
            <>
              {store.permissions && store.permissions.includes('option_catalogs_read') ? (
                <>
                  <WarperBackages backageKey='OPTION_CATALOG_NUM'>
                    <OptionCatalog />
                  </WarperBackages>
                </>
              ) : (
                <Error403 />
              )}
            </>
          }
        />
        <Route
          path='options'
          element={
            <>
              {store.permissions && store.permissions.includes('options_read') ? (
                <WarperBackages backageKey='OPTION_NUM'>
                  <>
                    <TitlePage
                      info={{
                        title: t('option'),
                        home: t('products'),
                        linkHome: '/products',
                      }}
                    />
                    <OptionTable />
                  </>
                </WarperBackages>
              ) : (
                <Error403 />
              )}
            </>
          }
        />
        <Route
          path='add-option'
          element={
            <>
              {store.permissions && store.permissions.includes('options_create') ? (
                <WarperBackages backageKey='OPTION_NUM'>
                  <>
                    <TitlePage
                      info={{
                        title: t('addOption'),
                        home: t('products'),
                        linkHome: '/products',
                      }}
                    />
                    <AddOption isEdit={true} />
                  </>
                </WarperBackages>
              ) : (
                <Error403 />
              )}
            </>
          }
        />
        <Route
          path='edit-option/:id'
          element={
            <>
              {store.permissions && store.permissions.includes('options_update') ? (
                <WarperBackages backageKey='OPTION_NUM'>
                  <>
                    <TitlePage
                      info={{
                        title: t('editOption'),
                        home: t('products'),
                        linkHome: '/products',
                      }}
                    />
                    <AddOption isEdit={true} />
                  </>{' '}
                </WarperBackages>
              ) : (
                <Error403 />
              )}
            </>
          }
        />
        {/*   Manufacturer */}

        <Route
          path='manufacturer'
          element={
            <WarperBackages backageKey='MANUFACTURER_NUM'>
              <>
                {store.permissions && store.permissions.includes('manufacturers_read') ? (
                  <>
                    <Manufacturer />
                  </>
                ) : (
                  <Error403 />
                )}
              </>
            </WarperBackages>
          }
        />

        <Route index element={<Navigate to='/category/list' />} />
      </Route>
    </Routes>
  )
}

export default CategoryPage
