import {Stack, Typography} from '@mui/material'
import React from 'react'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'
import RedCyrcle from '../../svg/RedCyrcle'

export default function TabsLangWithFlag({
  setActiveLang,
  activeLang,
  languages,
  msgErr,
  name,
  pb,
}: any) {
  const store = useSelector((state: RootState) => state.vender)

  return (
    <Stack
      position={'absolute'}
      top={pb ? '-28px' : '-27px'}
      left={store.isRtl !== 0 ? '0' : 'auto'}
      right={store.isRtl === 0 ? '0' : 'auto'}
      gap='16px'
      direction={'row'}
    >
      {languages.map((e: any, i: number) => (
        <Typography
          onClick={() => {
            setActiveLang(e.code)
          }}
          key={i}
          sx={{
            color: activeLang === e.code ? 'var(--main-color)' : 'var(--bs-body-color)',
            borderBottom: activeLang === e.code ? '1px solid var(--main-color)' : 'auto',
            paddingBottom: pb || '10px',
            position: 'relative',
            cursor: 'pointer',
          }}
        >
          {msgErr && name && e.code && msgErr[`${name}.${e.code}`] && activeLang !== e.code && (
            <Stack top='4px' right={0} position={'absolute'}>
              <RedCyrcle />
            </Stack>
          )}

          {e.lang}
        </Typography>
      ))}
    </Stack>
  )
}
