import React, {useEffect, useState} from 'react'
import {Stack, Typography} from '@mui/material'
import Table from './Table'
import {useTranslation} from 'react-i18next'
import TitlePage from '../../customTable/TitlePage'
import iMedia from '../../../interface/iMedia'
import InputsText from './InputsText'
import * as Yup from 'yup'

import RestSaveButton from '../../customTable/RestSaveBtns'
import Media from '../../../Api/media'
import CardMedia from './CardMedia'
import {PageSchema} from '../../../yup/Page'
import {toast} from 'react-toastify'
import PageApi from '../../../Api/Page'
import {useNavigate, useParams} from 'react-router-dom'
import ErrorMsg from '../../customTable/ErrorMsg'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import Loading from '../../customTable/LoadingForm'
import ValidationFun from '../../../yup/ValidationFun'
import MySelect from '../../customTable/CustomSelectTest'
export default function Form() {
  const {t} = useTranslation()
  const [page, setPage] = useState<any>({description: {}, status: 1})
  const [test, setTest] = useState({description: {}})
  const [isLoadingBtn, setIsLoadingBtn] = useState(false)
  const [iloading, setIsLoading] = useState(false)
  const {id} = useParams()
  const navigate = useNavigate()

  const bannerInfo = {
    title: id ? t('editPage') : t('createPage'),
    home: t('home'),
    linkHome: '/',
  }
  const [mediaCard, setMediaCard] = useState([])
  const getPageDtails = (id: any) => {
    PageApi.getDitals(
      id,
      (res: any) => {
        setPage(res.data)

        setTest({description: res.data.description})
      },
      setIsLoading
    )
  }

  useEffect(() => {
    if (id) {
      getPageDtails(id)
    } else {
    }
  }, [id])
  const [isLoadingMedia, setIsLoadingMedia] = useState(false)
  const getMedia = () => {
    Media.getMediaAttachment((res: any) => {
      setMediaCard(res.data)
    }, setIsLoadingMedia)
  }
  useEffect(() => {
    getMedia()
  }, [])
  const [languages, setlanguages] = useState(['en'])
  const store = useSelector((state: RootState) => state.vender)
  const [errors, setErrors] = useState({})
  useEffect(() => {
    setlanguages(store.storeLangs)
  }, [store.storeLangs])

  const handelSubmit = () => {
    ValidationFun({...page, ...test}, PageSchema(languages), setErrors, (value: any) => {
      if (id) {
        PageApi.putDetails(
          {...page, ...test},
          id,
          () => {
            // setPage({description: {}, status: 1})
            // navigate('/storeManagement/page')
            setMsgErr('')
          },
          setIsLoadingBtn
        )
      } else {
        PageApi.add(
          {...page, ...test},
          () => {
            setPage({description: {}, status: 1})
            navigate('/storeManagement/page')
            setMsgErr('')
          },
          setIsLoadingBtn
        )
      }
    })
  }
  const [msgErr, setMsgErr] = React.useState<string>(' ')

  return (
    <Stack>
      <TitlePage info={bannerInfo} />
      <>
        {!iloading && (
          <>
            {' '}
            <Stack justifyContent={'space-between'} direction={'row'}>
              <Stack
                sx={{marginTop: '30px'}}
                width={'100%'}
                gap={'15px'}
                direction={{xs: 'column', sm: 'row'}}
              >
                <Stack
                  width={{xs: '100%', sm: '65%'}}
                  // height={'700px'}
                  bgcolor={'var(--bs-app-footer-bg-color)'}
                  borderRadius={'8px'}
                >
                  <InputsText
                    errors={errors}
                    test={test}
                    setTest={setTest}
                    msgErr={msgErr}
                    setMsgErr={setMsgErr}
                    page={page}
                    setPage={setPage}
                  />
                </Stack>

                <Stack
                  width={{xs: '100%', sm: '34%'}}
                  height={'1300px'}
                  bgcolor={'var(--bs-app-footer-bg-color)'}
                  borderRadius={'8px'}
                  sx={{overflowY: 'scroll'}}
                  p='24px'
                  position={'relative'}
                >
                  <Typography sx={{fontSize: '18px', fontWeight: '500'}}>{t('media')}</Typography>
                  {isLoadingMedia ? (
                    <Loading />
                  ) : (
                    <Stack gap='24px' mt='21px'>
                      {mediaCard.map((e, i) => (
                        <CardMedia item={e} key={i} />
                      ))}
                    </Stack>
                  )}
                </Stack>
              </Stack>
            </Stack>
            <Stack mt='60px' width={'100%'}>
              <RestSaveButton
                isLoading={isLoadingBtn}
                SaveText={t('saveChange')}
                cancelText={t('cancel')}
                submit={handelSubmit}
                cancel={() => {
                  navigate('/storeManagement/page')
                }}
              />
              <Stack alignItems={'flex-end'}>
                <ErrorMsg msgErr={msgErr} name='' />
              </Stack>
            </Stack>
          </>
        )}
      </>
    </Stack>
  )
}
