import axios from 'axios'
import {initializeApp} from 'firebase/app'
import {getFirestore} from 'firebase/firestore'
import {getStorage} from 'firebase/storage'

let app // متغير لتخزين التطبيق بعد التهيئة
let db
let storage

// دالة لجلب البيانات وتهيئة Firebase
const initializeFirebase = async () => {
  try {
    const {data} = await axios.get(
      'https://vendor.api.koninstore.com/api/v1/vendor/settings/all-languages'
    )

    const keys = {
      apiKey: 'AIzaSyB9Wug6E7WPeJYG2QBjOMd7pFqXz_dYU9Y',
      authDomain: 'koin-store5t.firebaseapp.com',
      projectId: 'koin-store5t',
      storageBucket: 'koin-store5t.appspot.com',
      messagingSenderId: '343362859110',
      appId: '1:343362859110:web:573c835672c55e6f30bda7',
      measurementId: 'G-KP9539MW2C',
    }

    // تهيئة Firebase
    app = initializeApp(keys)
    db = getFirestore(app)
    storage = getStorage(app)
  } catch (error) {
    console.error('Error initializing Firebase:', error)
  }
}

initializeFirebase()

export {app, db, storage}
