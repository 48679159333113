import {Stack} from '@mui/material'
import React from 'react'
import Label from '../../customTable/Label'

export default function LabelWithValue({label, value}: any) {
  return (
    <Stack flexWrap={'wrap'} alignItems={'center'} gap='5px' direction={'row'}>
      <Label color='#19224C' fw='500' fs='12px' text={label} />:
      <Label color='#787887' fw='500' fs='10px' text={value} />
    </Stack>
  )
}
