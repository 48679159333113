import {Button, Stack, Tooltip} from '@mui/material'
import React, {useEffect} from 'react'
import {useState} from 'react'
import ImgMsg from './ImgMsg'
import ErrorMsg from './NewMsgErr'
import SizeImage from './SizeImage'

export default function UploadImg({base64Image, setBase64Image, errors, name, hight, width}: any) {
  const [image, setImage] = useState(base64Image || '/media/avatars/img.svg')

  const handleImageChange = (event: any) => {
    const file = event.target.files[0]

    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      setBase64Image(reader.result)
      setImage(reader.result as string)
    }
    reader.onerror = (error) => {}
  }

  return (
    <Stack width={'169px'}>
      <Tooltip title='Only jpeg , jpg , png and svg image files are accepted'>
        <Stack
          width='150px'
          height={'150px'}
          padding={'0'}
          sx={{
            boxShadow: '0px 6.5px 19.5px 6.5px rgba(0, 0, 0, 0.075)',
            borderRadius: '7px',
          }}
        >
          <Button
            sx={{width: '100%', height: '100%', padding: '10px', borderRadius: '7px'}}
            component='label'
          >
            <img style={{width: '100%', height: '100%', borderRadius: '8px'}} src={image} alt='' />
            <input
              hidden
              accept='.jpg, .jpeg, .png, .svg'
              onChange={handleImageChange}
              multiple
              type='file'
            />
          </Button>
        </Stack>
      </Tooltip>
      <SizeImage width={width} hight={hight} />
      {errors && errors[name] && errors && typeof errors[name] === 'string' && (
        <ErrorMsg msgErr={errors[name]} />
      )}
    </Stack>
  )
}
