import {Avatar} from '@mui/material'
import React from 'react'

export default function Image({src, sx, className, style}: any) {
  return (
    <Avatar
      className={className || ''}
      sx={{
        width: '100%',
        height: '100%',
        borderRadius: '8px',
        backgroundColor: 'var(--bs-scrollbar-color) transparent',
        ...sx,
        ...style,
      }}
      src={src}
      alt=''
    >
      <img
        style={{width: '100%', height: '100%', borderRadius: '8px'}}
        src='./media/avatars/fillImage.svg'
        alt=''
      />
    </Avatar>
  )
}
