import React, {useState} from 'react'
import {Avatar, Stack, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import Actions from './Actions'
import Label from '../../customTable/Label'
import FixedNumber from '../../customTable/FixedNumber'
import Image from '../../customTable/Image'

export default function Row({item, order}: any) {
  const [editItem, setEditItem] = useState(item)

  const store = useSelector((state: RootState) => state.vender)

  return (
    <>
      <tr className='fw-bold text-muted '>
        <td>
          <Stack gap='5px' direction={'row'}>
            <Stack width={'50px'} borderRadius={'8px'} height={'50px'}>
              <Image
                sx={{width: '100%', height: '100%', borderRadius: '8px'}}
                src={editItem.image}
                alt=''
              />
            </Stack>
            <Stack>
              <Typography sx={{fontSize: '16px', fontWeight: '600', color: 'var(--bs-body-color)'}}>
                {item.name &&
                  (item.name[store.lang] || item.name[store.defLang]) &&
                  (item.name[store.lang] || item.name[store.defLang]).slice(0, 15)}
                {item.name &&
                  (item.name[store.lang] || item.name[store.defLang]) &&
                  (item.name[store.lang] || item.name[store.defLang]).length > 15 &&
                  '...'}
              </Typography>
              {item.order_options &&
                item.order_options.map((e: any, i: any) => (
                  <Typography key={i} sx={{color: 'var(--bs-body-color)'}}>
                    {e.option_name && (e.option_name[store.lang] || e.option_name[store.defLang])} :{' '}
                    <span style={{color: 'var(--bs-text-gray-700)'}}>
                      {e.option_value &&
                        (e.option_value[store.lang] || e.option_value[store.defLang])}
                    </span>{' '}
                  </Typography>
                ))}
            </Stack>
          </Stack>
        </td>
        <td style={{textAlign: store.isRtl === 0 ? 'start' : 'start'}}>
          <span className='text-muted me-2 fs-7 fw-semibold'>{editItem.model}</span>
        </td>
        <td style={{textAlign: store.isRtl === 0 ? 'start' : 'start'}}>
          <span className='text-muted me-2 fs-7 fw-semibold'>{editItem.quantity}</span>
        </td>
        <td style={{textAlign: store.isRtl === 0 ? 'start' : 'start'}}>
          {' '}
          <Stack
            className='text-muted me-2 fs-7 fw-semibold'
            height={'100%'}
            justifyContent={'center'}
          >
            <FixedNumber num={editItem.unit_price} />{' '}
          </Stack>
        </td>

        <td style={{textAlign: store.isRtl === 0 ? 'start' : 'start'}}>
          <Stack
            className='text-muted me-2 fs-7 fw-semibold'
            height={'100%'}
            justifyContent={'center'}
          >
            <FixedNumber num={editItem.sub_total} />
          </Stack>
        </td>
        <td
          style={{
            textAlign: store.isRtl === 0 ? 'start' : 'start',
          }}
        >
          <Stack
            className='text-muted me-2 fs-7 fw-semibold'
            height={'100%'}
            justifyContent={'center'}
          >
            <FixedNumber num={editItem.total} />
          </Stack>
        </td>
      </tr>
      <tr style={{borderBottom: '1px solid #ff000000'}}>
        {editItem.refund_data && editItem.refund_data.reason && (
          <td colSpan={7}>
            <Stack
              p='8px !important'
              bgcolor={'rgba(113, 204, 255, 0.1) !important'}
              borderRadius={'8px'}
            >
              <Label fs='12px' fw='600' text='refundReason' />
              <Label
                fs='12px'
                color='#7D8192'
                text={editItem.refund_data && editItem.refund_data.reason}
              />
              {editItem.refund_data &&
                editItem.refund_data.refund_amount &&
                editItem.refund_data.refund_amount && (
                  <Stack gap='5px' direction={'row'} alignItems={'center'}>
                    <Label fw='600' fs='12px' text='refundAmount' />:
                    <FixedNumber
                      color='#7D8192'
                      fs='12px'
                      num={
                        editItem.refund_data &&
                        editItem.refund_data.refund_amount &&
                        editItem.refund_data.refund_amount
                      }
                    />
                  </Stack>
                )}
            </Stack>
          </td>
        )}
      </tr>
      <tr>
        {editItem.refund_data && editItem.refund_data.reject_reason && (
          <td colSpan={7}>
            <Stack
              p='8px !important'
              bgcolor={'rgba(113, 204, 255, 0.1) !important'}
              borderRadius={'8px'}
            >
              <Label fs='12px' fw='600' text='rejectReason' />
              <Label
                fs='12px'
                color='#7D8192'
                text={editItem.refund_data && editItem.refund_data.reject_reason}
              />
            </Stack>
          </td>
        )}
      </tr>
    </>
  )
}
