import {Stack} from '@mui/material'
import React, {useState, useEffect} from 'react'
import CheckBox from './CheckBox'
import LangSelect from './SelectLang'
import LabelInput from '../../customTable/LabelInput'
import LangBtns from './LangBtns'
import RestSaveButton from '../../customTable/RestSaveBtns'
import Langs from '../../../Api/SettingLangs'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import SetLang from '../../../utils/SetLangs'
import {useTranslation} from 'react-i18next'
import ErrorMsg from '../../customTable/ErrorMsg'
import Loading from '../../customTable/LoadingForm'
interface Ilang {
  name: string
  id: number
}
export default function Languages() {
  const [isCheck, setIsCheck] = useState(0)

  const [data, setData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [defLang, setDefLang] = useState('')
  const store = useSelector((state: RootState) => state.vender)
  const {t} = useTranslation()
  const [old, setOld] = useState({})
  const [isLoadingLangs, setIsLoadingLangs] = useState(false)
  const getLangs = async () => {
    Langs.get((res: any) => {
      setLangs(res.data.all_languages)
      const selectedLangsCode = res.data.store_languages.map((e: any) => +e.id)
      setSelectedLang(selectedLangsCode)
      setOld(selectedLangsCode)
      setDefLang(
        store.langs.some((e: any) => e.code === store.defLang) ? store.defLang : store.langs[0].code
      )
      setIsCheck(store.langStatus || store.langStatus === 1 ? 1 : 0)
    }, setIsLoadingLangs)
    // const selectedLangs = await SetLang()
    // const selectedLangsCode = selectedLangs.map((e: any) => e.code)
  }
  const [selectedLang, setSelectedLang] = useState<any>([])
  const [langs, setLangs] = useState<Ilang[]>([])
  useEffect(() => {
    getLangs()
  }, [store.langs])
  useEffect(() => {
    // getLangs()
  }, [])
  const [msgErr, setMsgErr] = React.useState<string>('')

  const updateLang = async () => {
    if (selectedLang.length === 1 && selectedLang[0] === defLang && isCheck === 0) {
      setMsgErr('Choose one of the languages ​​or activate the default language')
    } else {
      setIsLoading(true)
      await Langs.update(
        {
          default_language_status: isCheck,
          languages: selectedLang.filter((e: any) => e !== defLang),
        },
        () => {
          localStorage.setItem('langs', JSON.stringify(selectedLang))
          window.location.reload()
        },
        setIsLoading
      )
    }
  }

  return (
    <Stack minHeight={'60vh'} position={'relative'}>
      {isLoadingLangs ? (
        <Loading />
      ) : (
        <Stack
          p='24px'
          borderRadius={'8px'}
          bgcolor={'var(--bs-app-header-base-bg-color)'}
          width={'100%'}
          minHeight={'351px'}
          justifyContent={'space-between'}
        >
          <Stack>
            <CheckBox
              options={langs.filter((e: any, i) => selectedLang.includes(e.id))}
              defLang={defLang}
              isCheck={isCheck}
              setIsCheck={setIsCheck}
            />

            <Stack gap='12px' mt='22px' maxWidth={'600px'}>
              {/* <label style={{display: 'flex', gap: '5px'}} htmlFor={'CODE'}> */}
              {/* <LabelInput text={t('defaultLang')} /> */}
              {/* </label> */}
              {langs.filter((e: any) => !selectedLang.includes(e.id)).length > 0 && (
                <LangSelect
                  name='test'
                  disabled={false}
                  setSelectedLang={setSelectedLang}
                  selectedLang={selectedLang}
                  setValues={setData}
                  isShowLang
                  value={{code: ''}}
                  option={
                    store.langStatus === 0
                      ? langs.filter((e: any) => !selectedLang.includes(e.id))
                      : langs
                          .filter((e: any) => !selectedLang.includes(e.id))
                          .filter((a: any) => a.code !== store.defLang)
                  }
                  values={data}
                />
              )}
            </Stack>
            <Stack>
              <LangBtns
                setIsCheck={setIsCheck}
                selectedLang={selectedLang}
                setSelectedLang={setSelectedLang}
                langs={langs.filter((e: any, i) => selectedLang.includes(e.id))}
              />
            </Stack>
          </Stack>

          <Stack>
            {store.permissions && store.permissions.includes('languages_update') && (
              <RestSaveButton
                isLoading={isLoading}
                SaveText={t('save')}
                cancelText={t('rest')}
                submit={updateLang}
                cancel={() => {
                  setSelectedLang(old)
                  setIsCheck(store.langStatus)
                }}
              />
            )}

            <ErrorMsg msgErr={msgErr} name='' />
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}
// name,
// values,
// option,
// disabled,
// value,
// setValues,
