import {Box, Stack} from '@mui/material'
import React, {useState, useEffect} from 'react'
import {RootState} from '../../../store'
import {useSelector} from 'react-redux'
import InputType from '../../customTable/InputType'
import RedStart from '../../customTable/RedStart'
import LabelInput from '../../customTable/LabelInput'
import {useTranslation} from 'react-i18next'
import TabsLangWithFlag from '../../customTable/LangTabs'
import MySelect from '../../customTable/CustomSelectTest'
import MenuApi from '../../../Api/menu'
import Switch from '../../../componet/Toastr/Switch'
import PageApi from '../../../Api/Page'
import {useParams} from 'react-router-dom'
import SimpleLoading from '../../customTable/SimpleLoading'
import ErrorMsg from '../../customTable/NewMsgErr'

export default function InputsText({data, setData, msgErr, isEdit, errors}: any) {
  const [selectedLang, setSelectedLang] = useState<any>([])
  const [defLang, setDefLang] = useState('')
  const store = useSelector((state: RootState) => state.vender)
  const {t} = useTranslation()

  const getLangs = async () => {
    setSelectedLang(store.langs)
    setDefLang(
      store.langs.some((e: any) => e.code === store.defLang) ? store.defLang : store.langs[0].code
    )
  }
  useEffect(() => {
    getLangs()
  }, [store.langs])
  const [menuName, setMenuName] = useState([])
  const [pageName, setPageName] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingNames, setIsLoadingPdfNames] = useState(false)

  const getMenuName = () => {
    MenuApi.getNames((res: any) => {
      setMenuName(res.data)
    }, setIsLoading)
    PageApi.getNames((res: any) => {
      setPageName(res.data)
    }, setIsLoadingPdfNames)
  }
  const {id} = useParams()
  useEffect(() => {
    if (!isEdit) {
      getMenuName()
    }
  }, [])

  return (
    <Stack gap='24px'>
      <Stack gap={'25px'} justifyContent={'space-between'} direction={{sm: 'row', xs: 'column'}}>
        <Stack width={{sm: '50%', xs: '100%'}} gap='10px'>
          <label style={{display: 'flex', gap: '5px'}} htmlFor={''}>
            <LabelInput text={t('position')} />
            <RedStart />
          </label>
          <Stack
            sx={{border: '1px solid var(--bs-gray-300)', borderRadius: '6.18px'}}
            position={'relative'}
            width={{sm: '100%', xs: '100%'}}
            gap='10px'
          >
            {!isEdit && (
              <select
                style={{
                  padding: '12.0625px 13px 12.5px',
                  background: 'var(--bs-body-bg)',
                  color: ' var(--bs-text-dark)',
                  width: '98.5%',
                  border: 'none',
                  borderRadius: '6.18px',
                }}
                onChange={(e) => {
                  setData({...data, position: e.target.value})
                }}
                value={data.position}
              >
                <option value={0} disabled hidden>
                  Select
                </option>
                {[
                  {name: t('header'), id: 'header'},
                  {name: t('footer'), id: 'footer'},
                ].map((e: any, i: number) => (
                  <option value={e && e.id} key={i}>
                    {e.name && e.name}
                  </option>
                ))}
              </select>
            )}
          </Stack>
          <ErrorMsg msgErr={errors['position']} />
        </Stack>
        <Stack position={'relative'} width={{sm: '50%', xs: '100%'}} gap='10px'>
          {selectedLang.map((e: any, i: number) => (
            <>
              {e.code === defLang && (
                <>
                  <label style={{display: 'flex', gap: '5px'}} htmlFor={e.code}>
                    <LabelInput text={t('title')} />
                    <RedStart />
                  </label>
                  <Box position={'relative'} sx={{width: {sm: '100%', xs: '100%'}}}>
                    <InputType
                      name={'title'}
                      change={setData}
                      value={data}
                      type='text'
                      child={e.code}
                      errors={errors}
                    />
                    {/* <ErrorMsg msgErr={msgErr} name='address' /> */}
                  </Box>
                </>
              )}
            </>
          ))}
          <Stack
            position={'absolute'}
            right={store.isRtl === 0 ? '0' : 'auto'}
            left={store.isRtl === 1 ? '0' : 'auto'}
            top='24px'
          >
            <TabsLangWithFlag
              activeLang={defLang}
              setActiveLang={setDefLang}
              languages={selectedLang}
              name={'title'}
              msgErr={errors}
            />
          </Stack>
        </Stack>
      </Stack>
      <Stack gap='25px' justifyContent={'space-between'} direction={{sm: 'row', xs: 'column'}}>
        <Stack width={{sm: '50%', xs: '100%'}} gap='10px'>
          <label style={{display: 'flex', gap: '5px'}} htmlFor={''}>
            <LabelInput text={t('parent')} />
          </label>
          <Stack
            sx={{border: '1px solid var(--bs-gray-300)', borderRadius: '6.18px'}}
            position={'relative'}
            width={{sm: '100%', xs: '100%'}}
            gap='10px'
          >
            <select
              style={{
                padding: '12.0625px 13px 12.5px',
                background: 'var(--bs-body-bg)',
                border: 'none',
                color: ' var(--bs-text-dark)',
                borderRadius: '6.18px',
                width: '98.5%',
                // backgroundColor: 'var(--bs-gray-100)',
                // borderColor: 'var(--bs-gray-100)',
              }}
              onChange={(e) => {
                setData({...data, parent_id: e.target.value})
              }}
              value={data.parent_id}
            >
              <option value={0} disabled hidden>
                Select
              </option>
              {isLoading ? (
                <SimpleLoading />
              ) : (
                menuName.map((e: any, i: number) => (
                  <option value={e && e.id} key={i}>
                    {e.title && (e.title[store.lang] || e.title[store.defLang])}
                  </option>
                ))
              )}
            </select>
          </Stack>
          <ErrorMsg msgErr={errors['parent_id']} />
        </Stack>
        <Stack width={{sm: '50%', xs: '100%'}} gap='10px'>
          <label style={{display: 'flex', gap: '5px'}} htmlFor={''}>
            <LabelInput text={t('sort')} />
            <RedStart />
          </label>
          <Box position={'relative'} sx={{width: {sm: '100%', xs: '100%'}}}>
            <InputType errors={errors} name={'sort'} change={setData} value={data} type='number' />
          </Box>
        </Stack>
      </Stack>

      <Stack gap='25px' justifyContent={'space-between'} direction={{sm: 'row', xs: 'column'}}>
        <Stack width={{sm: '50%', xs: '100%'}} gap='10px'>
          <label style={{display: 'flex', gap: '5px'}} htmlFor={''}>
            <LabelInput text={t('pages')} />
            <RedStart />
          </label>
          <Stack
            sx={{border: '1px solid var(--bs-gray-300)', borderRadius: '6.18px'}}
            position={'relative'}
            width={{sm: '100%', xs: '100%'}}
            gap='10px'
          >
            <select
              style={{
                padding: '12.0625px 13px 12.5px',
                background: 'var(--bs-body-bg)',
                border: 'none',
                color: ' var(--bs-text-dark)',
                borderRadius: '6.18px',
                width: '98.5%',
              }}
              onChange={(e: any) => {
                if (e.target.value === '-1') {
                  setData({...data, page_id: e.target.value})
                } else {
                  setData({...data, page_id: e.target.value, url_link: {}})
                }
              }}
              value={data.page_id}
            >
              <option value={'-1'}>{t('noPages')}</option>
              {isLoadingNames ? (
                <SimpleLoading />
              ) : (
                pageName.map((e: any, i: number) => (
                  <option value={e && e.id} key={i}>
                    {e.title && (e.title[store.lang] || e.title[store.defLang])}
                  </option>
                ))
              )}
            </select>
          </Stack>
        </Stack>
        <Stack position={'relative'} width={{sm: '50%', xs: '100%'}} gap='10px'>
          {selectedLang.map((e: any, i: number) => (
            <>
              {e.code === defLang && (
                <>
                  <label style={{display: 'flex', gap: '5px'}} htmlFor={e.code}>
                    <LabelInput text={t('link')} />
                    {data.page_id === '-1' && <RedStart />}
                  </label>
                  <Box position={'relative'} sx={{width: {sm: '100%', xs: '100%'}}}>
                    <InputType
                      name={'url_link'}
                      change={setData}
                      value={data}
                      type='text'
                      child={e.code}
                      changeName='permalink'
                      isLink={1}
                      checked={data.page_id === '-1' ? false : true}
                      errors={errors}
                    />
                  </Box>
                </>
              )}
            </>
          ))}
          <Stack
            position={'absolute'}
            right={store.isRtl === 0 ? '0' : 'auto'}
            left={store.isRtl === 1 ? '0' : 'auto'}
            top='24px'
          >
            <TabsLangWithFlag
              activeLang={defLang}
              setActiveLang={setDefLang}
              languages={selectedLang}
              name={'url_link'}
              msgErr={errors}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}
