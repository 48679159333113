import {Avatar, CircularProgress, Stack, Typography} from '@mui/material'
import React, {useCallback, useState} from 'react'
import Customer from '../../Api/customer'
import {useTranslation} from 'react-i18next'
import Image from '../customTable/Image'

export default function UsersBox({setUser, Activeusers, setActiveUsers}: any) {
  const [search, setSearch] = useState('')
  const [timerId, setTimerId] = useState<number | null>(null)
  const [rows, setRows] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const GetUsers = async (search: string, page: number, pagerow: number) => {
    Customer.get(
      search,
      page,

      pagerow,
      (res: any) => {
        setRows(res.data)
      },
      setIsLoading
    )
    // const users = await Customer.get(search, page, pagerow)
    // setRows(users?.data?.data)

    // setRows(users.data.data)
    // setIsLoading(false)
  }
  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newSearchValue = e.target.value
      setSearch(e.target.value)
      if (timerId) {
        clearTimeout(timerId)
      }

      setTimerId(
        window.setTimeout(() => {
          if (newSearchValue) {
            GetUsers(newSearchValue, 1, 5)
          } else {
            setRows([])
          }
        }, 500)
      )
    },
    [timerId]
  )
  const {t} = useTranslation()

  return (
    <Stack p='22px 29px'>
      <Stack
        sx={{
          background: 'var(--bs-app-footer-bg-color)',
          border: '1px solid var(--bs-gray-300)',
          borderRadius: '6.2px',
          padding: '0 15px',
          gap: '10px',
        }}
        direction={'row'}
        position={'relative'}
      >
        <img style={{width: '25px'}} src='./media/avatars/icons/search.svg' alt='' />

        <input
          style={{
            background: 'var(--bs-app-footer-bg-color)',
            border: 'none',
            padding: '0.775rem 0',
            borderRadius: '6.2px',
            width: '100%',
            color: 'var(--bs-body-color)',
          }}
          placeholder='Search by username or email...'
          value={search}
          onChange={handleSearch}
          type='text'
        />
      </Stack>
      <Stack
        mt={'25px'}
        height={{xs: 'auto', lg: '64vh'}}
        sx={{overflowY: {xs: 'auto', lg: 'scroll'}}}
        gap='13px'
      >
        {isLoading && <CircularProgress />}
        {!isLoading && search && rows.length === 0 && (
          <Typography sx={{color: 'gray', textAlign: 'center'}}>{t('noUsers')}</Typography>
        )}
        {search &&
          rows.map((e: any, i: any) => (
            <Stack
              onClick={() => {
                setUser(e)
                setSearch('')
                // setActiveUsers([e, ...Activeusers.filter((a: any) => e.id !== a.id)])
              }}
              sx={{cursor: 'pointer'}}
              p={'13px 0'}
              key={i}
            >
              <Stack gap='16px' direction={{xs: 'column', lg: 'row'}}>
                <Image src={e.image} />
                <Stack>
                  <Typography sx={{fontSize: '15px', fontWeight: '600'}}>
                    {e.full_name && e.full_name}
                  </Typography>
                  {/* <Typography sx={{fontSize: '13px', fontWeight: '500', color: '#A1A5B7'}}>
                    {e.full_name && e.full_name}
                  </Typography> */}
                </Stack>
              </Stack>
            </Stack>
          ))}
        {!search &&
          Activeusers.map((e: any, i: any) => (
            <Stack
              onClick={() => {
                setUser(e)
                // setActiveUsers([...e, Activeusers.filter((a: any) => e.id !== a.id)])
              }}
              sx={{cursor: 'pointer'}}
              p={'13px 0'}
              key={i}
            >
              <Stack gap='16px' direction={'row'}>
                <Avatar src={e.image} />
                <Stack>
                  <Typography sx={{fontSize: '15px', fontWeight: '600'}}>
                    {e.full_name && e.full_name}
                  </Typography>
                  <Typography sx={{fontSize: '13px', fontWeight: '500', color: '#A1A5B7'}}>
                    {e.full_name && e.full_name}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          ))}
      </Stack>
    </Stack>
  )
}
