import React, {useEffect, useState} from 'react'
import {collection, addDoc, onSnapshot, getFirestore, getDocs, deleteDoc} from 'firebase/firestore'
import {db, storage} from '../../firebase' // استبدل "./firebaseConfig" بالمسار الصحيح لملف التكوين الخاص بك
import TitlePage from '../customTable/TitlePage'
import {useTranslation} from 'react-i18next'
import {Stack} from '@mui/material'
import UsersBox from './UsersBox'
import ChatBox from './ChatBox'
import ChatApi from '../../Api/chat'

function ChatComponent() {
  const [message, setMessage] = useState('')
  const [messages, setMessages] = useState([])
  const [allUsers, setUsers] = useState([])
  const {t} = useTranslation()

  const bannerInfo = {
    title: t('chat'),
    home: t('home'),
    linkHome: '/',
  }
  const [user, setUser] = useState({})
  const [myIds, setMyIds] = useState([])
  useEffect(() => {
    if (user.id) {
      const id = localStorage.getItem('id')
      const unsubscribe = onSnapshot(
        collection(db, `store_${id}`, 'users', `${user.id}`),
        (snapshot) => {
          const updatedMessages = snapshot.docs.map((doc) => doc.data())

          setMessages(updatedMessages.sort((a, b) => a.orderd - b.orderd))
        }
      )

      return () => {
        unsubscribe()
      }
    }
  }, [user])
  useEffect(() => {
    const id = localStorage.getItem('id')
    const unsubscribe = onSnapshot(collection(db, `store_${id}`, 'users', `ids`), (snapshot) => {
      const updatedMessages = snapshot.docs.map((doc) => doc.data())

      setMyIds(updatedMessages.sort((a, b) => new Date(b.date) - new Date(a.date)))
    })

    return () => {
      unsubscribe()
    }
  }, [])

  const id = localStorage.getItem('id')
  const [messagesCount, setmessagesCount] = useState(0)
  useEffect(() => {
    if (user.id) {
      setmessagesCount(messages.length)
    }
  }, [messages])
  const sendMessage = async (e) => {
    e.preventDefault()
    if (message.trim() === `store_${id}`) {
      return
    }
    setMessage('')
    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0')
    const day = currentDate.getDate().toString().padStart(2, '0')
    const hours = currentDate.getHours().toString().padStart(2, '0')
    const minutes = currentDate.getMinutes().toString().padStart(2, '0')
    const seconds = currentDate.getSeconds().toString().padStart(2, '0')
    const newDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
    await addDoc(collection(db, `store_${id}`, 'users', `${user.id}`), {
      storeId: id,
      message: message.trim(),
      sender: 'vendor',
      orderd: messagesCount + 1,
      date: newDate,
      userId: user.id,
      // dateString: now()>format('M d, Y')
    })
    setActiveUsers([user, ...Activeusers.filter((a) => user.id !== a.id)])
    const usersCollectionRef = collection(db, `store_${id}`, 'users', `ids`)

    const querySnapshot = await getDocs(usersCollectionRef)
    querySnapshot.forEach((doc) => {
      const existingUser = doc.data()
      if (existingUser.id === user.id) {
        deleteDoc(doc.ref)
      }
    })

    await addDoc(collection(db, `store_${id}`, 'users', `ids`), {
      id: user.id,
      date: newDate,
    })
    setmessagesCount(messagesCount + 1)
  }

  const getCollections = async () => {
    const db = getFirestore()

    try {
      const mainCollectionRef = collection(db, `store_${id}`, 'users', `ids`)
      const snapshot = await getDocs(mainCollectionRef)

      const collectionNames = []
      const allData = []
      snapshot.forEach((doc) => {
        const subCollections = doc.data()
        collectionNames.push(subCollections.id)
        allData.push(subCollections)
      })
      const users = await ChatApi.post(collectionNames)
      allData.sort((a, b) => new Date(b.date) - new Date(a.date))
      setActiveUsers(
        allData.map((item) => users?.data?.data?.find((element) => element.id === item.id))
      )
    } catch (error) {
      console.error('Error getting collections:', error)
    }
  }

  useEffect(() => {
    if (
      myIds.length > Activeusers.length ||
      (myIds.length === Activeusers.length &&
        myIds[0] &&
        Activeusers[0] &&
        myIds[0].id !== Activeusers[0].id)
    ) {
      getCollections()
    }
  }, [myIds])
  const [Activeusers, setActiveUsers] = useState([])
  return (
    <div>
      <TitlePage info={bannerInfo} />
      <Stack mt='30px' gap='32px' direction={{xs: 'column', lg: 'row'}}>
        <Stack
          borderRadius={'8px'}
          bgcolor={'var(--bs-app-footer-bg-color)'}
          // height={'572px'}
          width={{xs: '100%', lg: '30%'}}
        >
          <UsersBox Activeusers={Activeusers} setActiveUsers={setActiveUsers} setUser={setUser} />
        </Stack>
        <Stack
          borderRadius={'8px'}
          bgcolor={'var(--bs-app-footer-bg-color)'}
          width={{xs: '100%', lg: '70%'}}
        >
          <ChatBox
            message={message}
            setMessage={setMessage}
            sendMessage={sendMessage}
            messages={messages}
            user={user}
          />
        </Stack>
      </Stack>
    </div>
  )
}

export default ChatComponent
