import {Stack} from '@mui/material'
import React, {useState} from 'react'
import InputWithLabel from '../../customTable/LabelWithText'
import Label from '../../customTable/Label'
import MainBtn from '../../customTable/MainBtn'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import Domain from '../../../Api/domin'
import ValidationFun from '../../../yup/ValidationFun'
import {DomainSchema} from '../../../yup/DomainSchema'
import RestSaveButton from '../../customTable/RestSaveBtns'
import {useTranslation} from 'react-i18next'
import Table from './Table'
import {log} from 'console'

export default function Form({setOpen, dns, setDns, setIsDns}: any) {
  const {t} = useTranslation()
  const [data, setData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const store = useSelector((state: RootState) => state.vender)
  const [errors, setErrors] = useState({})
  const getDns = () => {
    ValidationFun(data, DomainSchema, setErrors, (value: any) => {
      Domain.requestDNS(
        data,
        (res: any) => {
          setDns(res)
        },
        setIsLoading
      )
    })
  }
  const addDomain = () => {
    ValidationFun(data, DomainSchema, setErrors, (value: any) => {
      Domain.add(
        data,
        (res: any) => {
          setIsDns(true)
          setOpen(false)
        },
        setIsLoading
      )
    })
  }
  return (
    <Stack gap='24px' p='24px'>
      <Label fw='600' text='connectDomain' />
      <InputWithLabel
        width={{xs: '100%'}}
        name={'domain'}
        data={data}
        setData={setData}
        text='domain'
        errors={errors}
      />
      {!dns && (
        <Stack alignItems={'flex-end'}>
          <MainBtn
            handelSubmit={getDns}
            isLoading={isLoading}
            isAmount
            width='auto'
            text='RequestDNS'
          />
        </Stack>
      )}

      {dns && (
        <Stack gap='24px'>
          <Label text='point' />
          <Label text='AddPoint' />
          <Table data={dns} />
          <Stack>
            <RestSaveButton
              isLoading={isLoading}
              isCancel
              SaveText={t('connect')}
              cancelText={t('cancel')}
              submit={addDomain}
              cancel={() => {
                setOpen(false)
              }}
            />
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}
