import {Stack} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import TitlePage from '../customTable/TitlePage'
import InputWithLabel from '../customTable/LabelWithText'
import MainBtn from '../customTable/MainBtn'
import WalletPage from '../../svg/WalletPage'
import Label from '../customTable/Label'
import WalletApi from '../../Api/wallet'
import {useLocation} from 'react-router-dom'
import {toast} from 'react-toastify'
import PopUp from '../customTable/PopUp'

export default function Wallet() {
  const {t} = useTranslation()
  const [isConnect, setIsConnect] = useState(!true)
  const [isLoading, setIsLoading] = useState(false)
  const location = useLocation()
  const [queryParams, setQueryParams] = useState({})

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)

    const params = Object.fromEntries(searchParams.entries())
    if (params.staus === 'SUCCESS') {
      localStorage.setItem('pay', 'true')
    }
    toast.success(params.message)

    setQueryParams(params)
  }, [location.search])
  const bannerInfo = {
    title: t('MyWallet'),
    home: t('home'),
    linkHome: '/',
  }
  const [isChange, setIsChange] = useState(false)
  return (
    <Stack height={'100%'}>
      <TitlePage info={bannerInfo} />
      <Stack
        width='100%'
        gap='24px'
        justifyContent={'center'}
        alignItems={'center'}
        mt='21px'
        bgcolor={'#fff'}
        p='32px'
        borderRadius={'8px'}
        height={'100%'}
      >
        <WalletPage />
        <Label
          align='center'
          mw='279px'
          fw='600'
          fs={localStorage.getItem('pay') === 'true' ? '14px' : '17px'}
          text={localStorage.getItem('pay') === 'true' ? 'connectedWallet' : 'connectWallet'}
        />
        <MainBtn
          isLoading={isLoading || isChange}
          handelSubmit={() => {
            WalletApi.post(
              {callback: window.location.href},
              (res: any) => {
                setIsChange(true)
                window.location.href = res.redirect_url
              },
              setIsLoading
            )
          }}
          width='auto'
          text={localStorage.getItem('pay') === 'true' ? 'changeWallet' : 'connect'}
        />
      </Stack>
      <PopUp open={isChange} width='xs'>
        <Stack p='24px' bgcolor={'#fff'}>
          <Label align='center' text='ChangeWalletMsg' />
        </Stack>
      </PopUp>
    </Stack>
  )
}
