import {createAsyncThunk} from '@reduxjs/toolkit'
import store from '../..'
import API from '../../../Api'
import {thunkCatchError} from '../../utils'
import {isBase64} from '../catogory/thunk'
import {IPageCatogories} from './types'
import {toast} from 'react-toastify'

const CATOGORY = {
  GET_option: '/vendor/options',
  CHANGE_STATUS: '/vendor/options/status',
  GET_NAMES_CATOGORY: '/vendor/option_catalogs/get/names',
  GET_BY_ID: '/vendor/options',
  DELATE_OPTION_DETAILS: 'vendor/option-details',
}

export const getOptionAction = createAsyncThunk(
  'option/option',
  thunkCatchError(async ({page = 1, pre_page = 10, search}: IPageCatogories) => {
    let query = `?page=${page}&per_page=${pre_page}`
    if (search) {
      query += `&search=${search}`
    }
    const URL = `${CATOGORY.GET_option}${query}`

    const response = await API.get(URL)

    return (
      {
        data: response.data?.data,
        pages: response.data?.pages,
      } || {}
    )
  })
)

export const changeOptionStatusAction = createAsyncThunk(
  'option/changeOptionStatusAction',
  thunkCatchError(async ({id, status}: {id: string; status: number}) => {
    const response = await API.put(`${CATOGORY.CHANGE_STATUS}/${id}`, {
      status: status,
    })
    return response.data
  })
)
export const deleteOptionAction = createAsyncThunk(
  'option/deleteOptionAction',
  thunkCatchError(async ({id}: {id: string}) => {
    const {data, pages} = store.getState()?.option.option
    try {
      await API.delete(`${CATOGORY.GET_option}/${id}`)

      const newData = data?.filter((item: any) => {
        return item.id != id
      })
      toast.success('Deleted successfully')

      return {
        data: newData,
        pages,
      }
    } catch (err: any) {
      toast.error(err.response.data.message)

      return {
        data: data,
        pages,
      }
    }
  })
)

//   add  option  to  store
export const addOptionAction = createAsyncThunk(
  'option/addOptionAction',
  thunkCatchError(async (data: any) => {
    // Convert images to base64
    const promises = data?.optionDetails?.map(async (item: any) => {
      if (
        item.option_image &&
        typeof item.option_image === 'object' &&
        item.option_image instanceof Blob
      ) {
        const reader = new FileReader()
        reader.readAsDataURL(item.option_image)
        const result = await new Promise<string>((resolve, reject) => {
          reader.onload = () => {
            const data = reader.result as string
            const base64 = data.split(',')[1]

            resolve(data)
          }
          reader.onerror = (error) => {
            reject(error)
          }
        })
        item.option_image = result
      } else {
        delete item.option_image
      }
    })
    await Promise.all(promises)

    data.option_catalog_id = data.option_catalog_id.value
    try {
      if (typeof data.option_image === 'string') {
        delete data.option_image
      }
      const response = await API.post(`${CATOGORY.GET_option}`, data)

      return response.data.data
    } catch (error: any) {
      return {massageError: error.response.data.message}
    }
  })
)

export const updateOptionAction = createAsyncThunk(
  'option/updateOptionAction',
  thunkCatchError(async ({id, data}: {id: string | number; data: any}) => {
    const response = await API.put(`${CATOGORY.GET_option}/${id}`, data)

    return response.data.data
  })
)

export const getCategoryNames = createAsyncThunk(
  'option/getCategoryNames',
  thunkCatchError(async () => {
    const response = await API.get(`${CATOGORY.GET_NAMES_CATOGORY}`)

    return response.data.data
  })
)
export const getOptionById = createAsyncThunk(
  'option/getOptionById',
  thunkCatchError(async ({id}: {id: string | number}) => {
    const response = await API.get(`${CATOGORY.GET_BY_ID}/${id}`)

    return response.data.data
  })
)

//   remove  oneOBTION
export const removeItemOptionAction = createAsyncThunk(
  'option/removeOptionAction',
  thunkCatchError(async ({id, option_id}: {id: string | number; option_id: string | number}) => {
    const response = await API.delete(`${CATOGORY.DELATE_OPTION_DETAILS}/${id}`, {
      data: {
        option_id: option_id,
      },
    })
    return {
      status: response.data.status,
      id: id,
    }
  })
)
export const updateItemOptionAction = createAsyncThunk(
  'option/updateItemOptionAction',
  thunkCatchError(async ({data}: {data: any}) => {
    const {id, option_id, ...rest} = data
    //  need  to  check
    let dataObj = {
      option_id: option_id,
      sort: rest.sort || rest.sort_order,
      option_value: rest.option_value,
    } as any
    if (rest.color) {
      dataObj.color = rest.color
    }
    if (rest.option_image) {
      if (typeof rest.option_image === 'object') {
        const reader = new FileReader()
        reader.readAsDataURL(rest.option_image)
        const result = await new Promise<string>((resolve, reject) => {
          reader.onload = () => {
            const data = reader.result as string
            resolve(data)
          }
          reader.onerror = (error) => {
            reject(error)
          }
        })
        dataObj.option_image = result
      } else {
        dataObj.option_image = rest.option_image
      }
    }

    //  check  if  image  is base46 or  not

    const response = await API.put(`${CATOGORY.DELATE_OPTION_DETAILS}/${id}`, dataObj)
    return response.data.data
  })
)
export const createItemOptionAction = createAsyncThunk(
  'option/createItemOptionAction',
  thunkCatchError(async ({data}: {data: any}) => {
    const {option_id, ...rest} = data
    //  need  to  check
    let dataObj = {
      option_id: option_id,
      sort: rest.sort || rest.sort_order,
      option_value: rest.option_value,
    } as any
    if (rest.color) {
      dataObj.color = rest.color
    }
    if (rest.option_image) {
      if (typeof rest.option_image === 'object') {
        const reader = new FileReader()
        reader.readAsDataURL(rest.option_image)
        const result = await new Promise<string>((resolve, reject) => {
          reader.onload = () => {
            const data = reader.result as string
            resolve(data)
          }
          reader.onerror = (error) => {
            reject(error)
          }
        })
        dataObj.option_image = result
      } else {
        dataObj.option_image = rest.option_image
      }
    }

    const response = await API.post(`${CATOGORY.DELATE_OPTION_DETAILS}`, dataObj)

    return response.data.data
  })
)

//   UPDATE  OPTIONS
export const updateOptionOne = createAsyncThunk(
  'option/updateOptionOne',
  thunkCatchError(async ({id, data}: {data: any; id: string | number}) => {
    //  need  to  check

    try {
      const response = await API.put(`${CATOGORY.GET_BY_ID}/${id}`, data)

      return response.data.data
    } catch (error: any) {
      return {massageError: error.response.data.message}
    }
  })
)
