import React, {useState} from 'react'
import {Avatar, Stack} from '@mui/material'

import Manufacturers from '../../Api/manufacturers'
import {RootState} from '../../store'
import {useSelector} from 'react-redux'
import SelectState from './SelectState'
import {useTranslation} from 'react-i18next'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {Link, useNavigate} from 'react-router-dom'
import OrderApi from '../../Api/order'
import {format, parseISO} from 'date-fns'
import SimpleLoading from '../customTable/SimpleLoading'
import NameText from '../customTable/NameText'
import StatusOrder from './StatusOrder'
import FixedNumber from '../customTable/FixedNumber'
import Image from '../customTable/Image'

export default function Row({item, i, isActive}: {item: any; i: number; isActive: any}) {
  const [state, setState] = useState<any>(item.status)
  const [editItem, setEditItem] = useState(item)

  const [isDeleted, setDeleted] = useState(true)

  const {lang, defLang} = useSelector((state: RootState) => state.vender)
  const {t} = useTranslation()
  const options = [
    {
      value: 'pending',
      label: t('pending'),
    },
    {
      value: 'preparing',
      label: t('preparing'),
    },
    {
      value: 'in_progress',
      label: t('inProgress'),
    },
    {
      value: 'refunded',
      label: t('refunded'),
    },
    {
      value: 'completed',
      label: t('completed'),
    },
    {
      value: 'canceled',
      label: t('canceled'),
    },
  ]
  const [changeState, setChangeState] = useState({value: editItem.status})
  const [isLoading, setIsLoading] = useState(false)

  const onchange = (state: any) => {
    OrderApi.putStatus(
      editItem.id,
      state,
      () => {
        setIsLoading(false)
      },
      setIsLoading
    )
  }
  const navigate = useNavigate()

  const onView = () => {
    navigate(`/orders/orders/${editItem.id}`)
  }

  return (
    <>
      {isDeleted && (
        <tr className='fw-bold text-muted '>
          <td style={{textAlign: 'start'}}>
            <span className='  fs-7 fw-semibold'>{editItem.order_number}</span>
          </td>
          <td>
            <Link to={`/customer/details/${editItem.user && editItem.user.id}`}>
              {' '}
              <Stack gap='15px' alignItems={'center'} direction={'row'}>
                <Stack width={'50px'} borderRadius={'50%'} height={'50px'}>
                  <Image
                    sx={{width: '50px', height: '50px', borderRadius: '50%'}}
                    src={editItem.user && editItem.user.image}
                    alt=''
                  />
                </Stack>

                <NameText text={editItem.user && editItem.user.full_name} />
              </Stack>
            </Link>
          </td>
          {isActive === 'allOrder' && (
            <td style={{textAlign: 'start'}}>
              {' '}
              <Stack height={'100%'} justifyContent={'center'} width='120px'>
                {<StatusOrder text={editItem.status} state />}
                {/* {isLoading ? (
                  <SimpleLoading />
                ) : (
                  <SelectState
                    check={!(store.permissions && store.permissions.includes('orders_update'))}
                    value={{
                      value: 'allOrder',
                      label: t('allOrder'),
                    }}
                    option={options}
                    onchange={onchange}
                    changeState={changeState}
                    setChangeState={setChangeState}
                    state
                  />
                )} */}
              </Stack>{' '}
            </td>
          )}

          <td style={{textAlign: 'start'}}>
            <span className='text-muted  fs-7 fw-semibold'>
              {' '}
              <FixedNumber num={editItem.total} />{' '}
            </span>
          </td>
          <td style={{textAlign: 'start'}}>
            <span className='text-muted  fs-7 fw-semibold'>
              {(editItem.payment_method &&
                (editItem.payment_method[lang] || editItem.payment_method[defLang])) ||
                '-'}
            </span>
          </td>
          <td style={{textAlign: 'start'}}>
            <span className='text-muted  fs-7 fw-semibold'>
              {editItem.updated_at}
              {/* {editItem.created_at && format(parseISO(editItem.created_at), 'yyyy-MM-dd')} */}
            </span>
          </td>
          <td style={{textAlign: 'start'}}>
            <span className='text-muted  fs-7 fw-semibold'> {editItem.updated_at}</span>
          </td>

          <td style={{textAlign: 'end'}}>
            <Stack
              alignItems={'center'}
              sx={{background: 'var(--bs-light)'}}
              borderRadius={'8px'}
              className='menu-item '
            >
              <a
                style={{display: 'flex', gap: '5px', color: '#008dff'}}
                className='menu-link'
                onClick={onView}
              >
                <img
                  src={toAbsoluteUrl('/media/avatars/eye.png')}
                  alt='SVG Image'
                  style={{width: '15px', marginRight: '5px'}}
                />

                {t('view')}
              </a>
            </Stack>
          </td>
        </tr>
      )}
    </>
  )
}
