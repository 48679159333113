import {createSlice} from '@reduxjs/toolkit'
import {FetchStatus} from '../../types'
import {
  createItemBulkAction,
  createProductAction,
  deleteProductAction,
  deleteProductImageAction,
  getBulkById,
  getProductById,
  getProductName,
  ProductsAction,
  removeBulkAction,
  updateItemBulkAction,
  updateProductAction,
  uploadProductFileImage,
} from './thunk'
import {IinitialState} from './types'

const initialState: IinitialState = {
  products: {
    data: [],
    pages: null,
  },
  product: {},
  bulkDiscount: [],
  nameProduct: [],
  status: FetchStatus.idle,
  error: null,
}

const ProductsSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setProduct: (state, action) => {
      state.product = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(ProductsAction.fulfilled, (state, action) => {
      state.status = FetchStatus.completed
      state.products = action.payload
    })

    builder.addCase(ProductsAction.rejected, (state, action) => {
      state.status = FetchStatus.error
      state.error = action.payload as string
    })

    builder.addCase(ProductsAction.pending, (state, _) => {
      state.status = FetchStatus.pending
    })

    //      categoryDetailsAction
    builder.addCase(deleteProductAction.fulfilled, (state, action) => {
      //  state.status = FetchStatus.completed
      state.products.data = action.payload.data

      //  state.categories = action.payload
    })

    builder.addCase(getProductById.fulfilled, (state, action) => {
      state.status = FetchStatus.completed
      state.product = action.payload
    })
    // uploadFileImage
    builder.addCase(uploadProductFileImage.fulfilled, (state, action) => {
      state.status = FetchStatus.completed
      state.product = action.payload
    })

    builder.addCase(getBulkById.fulfilled, (state, action) => {
      state.status = FetchStatus.completed
      state.bulkDiscount = action.payload
    })
    //  bluk  discount getBulkById
    builder.addCase(removeBulkAction.fulfilled, (state, action) => {
      state.status = FetchStatus.completed
      state.bulkDiscount = action.payload
    })
    //  bluk  discount updateItemBulkAction
    builder.addCase(updateItemBulkAction.fulfilled, (state, action) => {
      state.status = FetchStatus.completed
      state.bulkDiscount = action.payload
    })
    builder.addCase(createItemBulkAction.fulfilled, (state, action) => {
      state.status = FetchStatus.completed
      state.bulkDiscount = action.payload
    })

    //

    //    builder.addCase(deleteCategoryAction.rejected, (state, action) => {
    //      state.status = FetchStatus.error
    //      state.error = action.payload as string
    //    })

    //    builder.addCase(deleteCategoryAction.pending, (state, _) => {
    //      state.status = FetchStatus.pending
    //    })
    //     //     add  catoegory  to  store  after  create  new  category and  add  to  server
    builder.addCase(createProductAction.fulfilled, (state, action) => {
      // state.status = FetchStatus.completed
      //  state.categories.data.push(action.payload)
    })

    builder.addCase(createProductAction.rejected, (state, action) => {
      state.status = FetchStatus.error
      state.error = action.payload as string
    })

    builder.addCase(createProductAction.pending, (state, _) => {
      state.status = FetchStatus.pending
    })
    // updateProductAction
    builder.addCase(updateProductAction.fulfilled, (state, action) => {
      // state.status = FetchStatus.completed
      state.product = action.payload
    })
    // deleteProductImageAction
    builder.addCase(deleteProductImageAction.fulfilled, (state, action) => {
      // state.status = FetchStatus.completed
      state.product = action.payload
    })

    //     categoryDetailsAction
    //     builder.addCase(categoryDetailsAction.fulfilled, (state, action) => {
    //       state.status = FetchStatus.completed
    //       state.category = action.payload
    //     })

    //     builder.addCase(categoryDetailsAction.rejected, (state, action) => {
    //       state.status = FetchStatus.error
    //       state.error = action.payload as string
    //     })

    //     builder.addCase(categoryDetailsAction.pending, (state, _) => {
    //       state.status = FetchStatus.pending
    //     })

    //       get   name  category

    builder.addCase(getProductName.fulfilled, (state, action) => {
      state.status = FetchStatus.completed
      state.nameProduct = action.payload
    })
  },
})
export const actionsProducts = ProductsSlice.actions

export default ProductsSlice.reducer
